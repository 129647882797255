import React, { useState } from "react";

import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";

import { Divider } from "@mui/material";

import { Modal } from "react-bootstrap";

const PARTS_QUOTE = "partsQuote";
const REPAIR_QUOTE = "repairQuote";
const WARRANTY_CLAIM = "walletClaim";
const PARTS_ORDER = "partsOrder";
const PARTS_RETURN = "partsReturn";
const TIMESHEET = "timesheet";

const OrderPartsForEquipment = ({ show, hideModal, handleSnack, handleOpenPartOrderCreateModal }) => {
  const [searchValue, setSearchValue] = useState("");
  const [orderType, setOrderType] = useState("");

  // change the input value
  const handleChangeValue = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  // select the order type
  const handleSelectOrderType = (orderValue) => {
    if (!searchValue) {
      handleSnack("info", "Please enter the required details.");
      return;
    }
    setOrderType(orderValue);
  };

  // submit
  const handleSubmit = () => {
    if (!searchValue) {
      handleSnack("info", "Please enter the required details.");
      return;
    } else if (!orderType) {
      handleSnack("info", "Please Select the request type.");
      return;
    }
    handleOpenPartOrderCreateModal();
  };

  return (
    <Modal show={show} onHide={hideModal} size={"xl"}>
      <Modal.Body style={{ backgroundColor: "#f4f4f4" }}>
        <h4>Generative AI</h4>
        <Divider sx={{ mb: 2 }} />
        <div className="card border p-3" style={{ backgroundColor: "#f2edf987" }}>
          <div className="row input-fields">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <div className="customselectsearch">
                  <div className="form-control-ai-search">
                    <ConstructionOutlinedIcon className="text-primary" style={{ fontSize: 25 }} />
                  </div>
                  <div className="form-control-search-input">
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={searchValue}
                      autoComplete="off"
                      onChange={handleChangeValue}
                      placeholder={"Ask whatever you want or make a request..."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ justifyContent: "space-evenly" }}>
            <div
              className={`card border px-5 py-2 font-size-13 font-weight-500 cursor ${
                orderType === PARTS_QUOTE ? "bg-primary text-white" : "order-for-parts-equ"
              }`}
              onClick={() => handleSelectOrderType(PARTS_QUOTE)}
            >
              Parts Quote
            </div>
            <div
              className={`card border px-5 py-2 font-size-13 font-weight-500 cursor ${
                orderType === REPAIR_QUOTE ? "bg-primary text-white" : "order-for-parts-equ"
              }`}
              onClick={() => handleSelectOrderType(REPAIR_QUOTE)}
            >
              Repair Quote
            </div>
            <div
              className={`card border px-5 py-2 font-size-13 font-weight-500 cursor ${
                orderType === WARRANTY_CLAIM ? "bg-primary text-white" : "order-for-parts-equ"
              }`}
              onClick={() => handleSelectOrderType(WARRANTY_CLAIM)}
            >
              Warranty Claim
            </div>
            <div
              className={`card border px-5 py-2 font-size-13 font-weight-500 cursor ${
                orderType === PARTS_ORDER ? "bg-primary text-white" : "order-for-parts-equ"
              }`}
              onClick={() => handleSelectOrderType(PARTS_ORDER)}
            >
              Parts Order
            </div>
            <div
              className={`card border px-5 py-2 font-size-13 font-weight-500 cursor ${
                orderType === PARTS_RETURN ? "bg-primary text-white" : "order-for-parts-equ"
              }`}
              onClick={() => handleSelectOrderType(PARTS_RETURN)}
            >
              Parts Return
            </div>
            <div
              className={`card border px-5 py-2 font-size-13 font-weight-500 cursor ${
                orderType === TIMESHEET ? "bg-primary text-white" : "order-for-parts-equ"
              }`}
              onClick={() => handleSelectOrderType(TIMESHEET)}
            >
              Timesheet
            </div>
          </div>
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
            Cancel
          </button>
          <button className="btn bg-primary text-white" onClick={handleSubmit}>
            Create Request
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrderPartsForEquipment;
