import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import { FONT_STYLE_SELECT } from "../../common/constants";
import { callPostApi } from "../../../services/ApiCaller";
import { API_SUCCESS } from "../../../services/ResponseCode";
import { CLAIM_MASTER_URL } from "../../../services/CONSTANTS";
import { claimRequestObj } from "../warrantyManagementConstants";
import ClaimRequestModal from "../claimMaster/ClaimRequestModal";
import ClaimOrderRequestModal from "../claimOrderManagement/ClaimOrderRequestModal";
import NewClaimOrderRequestModal from "../claimOrderManagement/orderRequestNew/NewClaimOrderRequestModal";

const requestTypeOptions = [
    { label: "Warranty", value: "WARRANTY" },
    { label: "Repair", value: "REPAIR" },
    { label: "Maintenance", value: "MAINTENANCE" },
];

const EquipmentCreateModal = ({
    show,
    hideModal,
    handleSnack,
    serialNumber,
    equipmentRecord,
    // setClaimRecordId,
    // setClaimRecordDetail,
    handleShowClaimDetailsModal,
    openClaimRequestModal,
    handleOpenClaimRequestModal,
    handleCloseRequestModal,
}) => {
    const [recordData, setRecordData] = useState({
        requestType: "",
        serialNumber: serialNumber || "",
        description: "",
        reference: "",
    });

    const [claimRecordId, setClaimRecordId] = useState(null);
    const [claimOrderId, setClaimOrderId] = useState(null);
    const [evaluationId, setEvaluationId] = useState(null);
    const [assesstmentId, setAssesstmentId] = useState(null);
    const [claimRecordDetail, setClaimRecordDetail] = useState(null);

    const handleInputFieldsChange = (e) => {
        const { name, value } = e.target;
        setRecordData({ ...recordData, [name]: value });
    };

    // create new claim create
    const handleCreateClaimRecord = () => {
        const rObj = {
            ...claimRequestObj,
            modelNumber: equipmentRecord?.model,
            equipmentNumber: equipmentRecord?.equipmentNumber,
            serialNumber: equipmentRecord?.makerSerialNumber,
            claimStatus: "REGISTERED",
            claimType: "STANDARD",
            payer: "CUSTOMER",
            claimNumber: Math.floor(Math.random() * 9000) + 1000,
            warrantyId: equipmentRecord?.warrantyId,
        };
        const rUrl = `${CLAIM_MASTER_URL}`;
        callPostApi(null, rUrl, rObj, (response) => {
            if (response.status === API_SUCCESS) {
                const responseData = response.data;
                setClaimRecordId(responseData.claimId);
                setClaimRecordDetail(responseData);
                handleSnack(
                    "success",
                    `Claim Number ${responseData.claimNumber} Created successfully.`
                );
                handleOpenClaimRequestModal();
            } else {
                handleSnack("error", `Something went wrong.`);
            }
        });
    };

    return (
        <>
            <Modal show={show} onHide={hideModal} size="md" centered>
                <Modal.Body>
                    <h5 className="modal-title" id="exampleModalLabel">
                        Create Warranty Request
                    </h5>
                    <p>
                        Generate a warranty / service request by using the
                        verified data.
                    </p>
                    <div className="row input-fields mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">
                                    REQUEST TYPE
                                </label>
                                <Select
                                    // defaultValue={selectedOption}
                                    onChange={(e) =>
                                        setRecordData({
                                            ...recordData,
                                            requestType: e,
                                        })
                                    }
                                    options={requestTypeOptions}
                                    value={recordData.requestType}
                                    styles={FONT_STYLE_SELECT}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">
                                    SERIAL NUMBER
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="serialNumber"
                                    placeholder="Serial Number"
                                    disabled
                                    value={recordData.serialNumber}
                                    onChange={handleInputFieldsChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">
                                    DESCRIPTION
                                </label>
                                <textarea
                                    className="form-control border-radius-10 text-primary"
                                    name="description"
                                    value={recordData.description}
                                    cols="30"
                                    rows="3"
                                    placeholder="Placeholder(Optional)"
                                    onChange={handleInputFieldsChange}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">
                                    REFERENCE
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="reference"
                                    placeholder="Reference"
                                    value={recordData.reference}
                                    onChange={handleInputFieldsChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-between"></div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary w-100"
                        onClick={handleCreateClaimRecord}
                    >
                        Create
                    </button>
                    <button
                        className="btn btn-border-primary w-100"
                        onClick={hideModal}
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
            {openClaimRequestModal && (
                <NewClaimOrderRequestModal
                    show={openClaimRequestModal}
                    hideModal={handleCloseRequestModal}
                    handleSnack={handleSnack}
                    claimRecordDetail={claimRecordDetail}
                    claimOrderId={claimOrderId}
                    setClaimOrderId={setClaimOrderId}
                    claimRecordId={claimRecordId}
                    setClaimRecordId={setClaimRecordId}
                    assesstmentId={assesstmentId}
                    setAssesstmentId={setAssesstmentId}
                    evaluationId={evaluationId}
                    setEvaluationId={setEvaluationId}
                    byAuthCode={true}
                    // // openPartCreateModal={openPartCreateModal}
                    // // handleShowPartCreateModal={handleShowPartCreateModal}
                />
            )}
            {/* {openClaimRequestModal && (
                <ClaimOrderRequestModal
                    show={openClaimRequestModal}
                    hideModal={handleCloseRequestModal}
                    handleSnack={handleSnack}
                    claimRecordDetail={claimRecordDetail}
                    claimOrderId={claimOrderId}
                    setClaimOrderId={setClaimOrderId}
                    claimRecordId={claimRecordId}
                    setClaimRecordId={setClaimRecordId}
                    assesstmentId={assesstmentId}
                    setAssesstmentId={setAssesstmentId}
                    evaluationId={evaluationId}
                    setEvaluationId={setEvaluationId}
                    byAuthCode={true}
                    // // openPartCreateModal={openPartCreateModal}
                    // // handleShowPartCreateModal={handleShowPartCreateModal}
                />
            )} */}
        </>
    );
};

export default EquipmentCreateModal;
