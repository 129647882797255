import React, { useEffect, useState } from "react";

import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Tooltip, TextField, FormControlLabel, FormGroup, Switch } from "@mui/material";

import { Modal } from "react-bootstrap";
import Select from "react-select";
import { FONT_STYLE, FONT_STYLE_SELECT } from "../../common/constants";
import {
  authClaimentOptions,
  claimRequestObj,
  claimRequestTypeOptions,
  familyOptions,
  makeOptions,
  questionsOptions,
} from "../warrantyManagementConstants";
import SearchBox from "../../common/SearchBox";
import { customerSearch, machineSearch } from "../../../services/searchServices";
import { callGetApi, callPostApi, callPutApi } from "../../../services/ApiCaller";
import { API_SUCCESS } from "../../../services/ResponseCode";
import { CLAIM_MASTER_URL } from "../../../services/CONSTANTS";
import { isEmptySelect } from "../../common/textUtilities";
import { ReadOnlyField } from "../../common/ReadOnlyField";
import Moment from "react-moment";

const WarrantyRequestAuthorization = ({
  show,
  hideModal,
  handleSnack,
  pwaNumber,
  setPwaNumber,
  warrantyRequestType = "",
  claimRecordId,
  setClaimRecordId,
  handleGoBackToQurestionsModal,
  autoReqObj,
}) => {
  const [tabValue, setTabValue] = useState("generalRequest");

  const [recordData, setRecordData] = useState({
    ...claimRequestObj,
    // claimType: warrantyRequestType || "",
    claimType: autoReqObj?.requestType || "",
    customerNumber: autoReqObj?.customerNumber || "",
    customerName: autoReqObj?.customerName || "",
    // claiment: authClaimentOptions[1],
    claiment: "",
    climentDetails: "",
    requestDescription: "",
    // make: makeOptions[0],
    // family: familyOptions[0],
    make: "",
    family: "",
    usedFor: "",
    usageCondition: "",
  });

  const [noOptionsModel, setNoOptionsModel] = useState(false);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);
  const [noOptionsMake, setNoOptionsMake] = useState(false);
  const [searchModelResults, setSearchModelResults] = useState([]);
  const [searchSerialResults, setSearchSerialResults] = useState([]);
  const [searchMakeResults, setSearchMakeResults] = useState([]);

  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);
  const [isNewCreated, setIsNewCreated] = useState(false);

  const [viewOnlyTab, setViewOnlyTab] = useState({
    gerenalViewOnly: false,
    machineViewOnly: false,
    customerViewOnly: false,
  });

  useEffect(() => {
    if (claimRecordId && !isNewCreated) {
      const rUrl = `${CLAIM_MASTER_URL}/${claimRecordId}`;
      callGetApi(null, rUrl, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          // request type
          const _claimType = claimRequestTypeOptions.find((obj) => obj.value === responseData.claimType);

          // make
          //   const _make = makeOptions.find((obj) => obj.value === responseData?.make);
          const _make = responseData?.make;

          // family
          //   const _family = familyOptions.find((obj) => obj.value === responseData?.family);
          const _family = responseData?.family;

          setRecordData({
            ...recordData,
            ...responseData,
            claimType: _claimType,
            // make: _make || makeOptions[0],
            // family: _family || familyOptions[0],
            make: _make || "",
            family: _family || "",
          });

          setViewOnlyTab({
            gerenalViewOnly: true,
            machineViewOnly: true,
            customerViewOnly: true,
          });

          setPwaNumber(responseData.authorizationCode);
        }
      });
    }
  }, [claimRecordId, isNewCreated]);

  // select option change
  const handleSelectChange = (e, keyName) => {
    setRecordData({ ...recordData, [keyName]: e });
  };

  // select option change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // Machine search based on model and serial number
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchModelResults([]);
    setSearchSerialResults([]);
    setSearchMakeResults([]);

    if (searchMachinefieldName === "model") {
      recordData.modelNumber = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    } else if (searchMachinefieldName === "serialNo") {
      recordData.serialNumber = searchText;
      searchQueryMachine = searchText
        ? recordData.modelNumber
          ? `model:${recordData.modelNumber} AND makerSerialNumber~` + searchText
          : "makerSerialNumber~" + searchText
        : "";
    } else if (searchMachinefieldName === "maker") {
      recordData.make = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    }
    if (searchQueryMachine) {
      await machineSearch(searchQueryMachine)
        .then((result) => {
          if (result) {
            if (searchMachinefieldName === "model") {
              if (result && result.length > 0) {
                const _result = new Set();
                const uniqueModelRes = result.filter((item) => {
                  const keyValue = item[searchMachinefieldName];
                  if (_result.has(keyValue)) {
                    return false;
                  } else {
                    _result.add(keyValue);
                    return true;
                  }
                });
                setSearchModelResults(uniqueModelRes);
                setNoOptionsModel(false);
              } else {
                setNoOptionsModel(true);
              }
            } else if (searchMachinefieldName === "serialNo") {
              if (result && result.length > 0) {
                const _result = new Set();
                const uniqueSerialNumberRes = result.filter((item) => {
                  const keyValue = item[searchMachinefieldName];
                  if (_result.has(keyValue)) {
                    return false;
                  } else {
                    _result.add(keyValue);
                    return true;
                  }
                });
                setSearchSerialResults(uniqueSerialNumberRes);
                setNoOptionsSerial(false);
              } else {
                setNoOptionsSerial(true);
              }
            } else if (searchMachinefieldName === "maker") {
              if (result && result.length > 0) {
                const _result = new Set();
                const uniqueMakeRes = result.filter((item) => {
                  const keyValue = item[searchMachinefieldName];
                  if (_result.has(keyValue)) {
                    return false;
                  } else {
                    _result.add(keyValue);
                    return true;
                  }
                });
                setSearchMakeResults(uniqueMakeRes);
                setNoOptionsMake(false);
              } else {
                setNoOptionsMake(true);
              }
            }
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the machine!");
        });
    } else {
      searchMachinefieldName === "model"
        ? setSearchModelResults([])
        : searchMachinefieldName === "maker"
        ? setSearchMakeResults([])
        : setSearchSerialResults([]);
    }
  };

  // Select machine from the search result
  const handleModelSelect = (type, currentItem) => {
    if (type === "model") {
      setRecordData({
        ...recordData,
        modelNumber: currentItem.model,
        // make: currentItem.maker,
        // // equipmentId: currentItem.id,
      });
      setSearchModelResults([]);
    } else if (type === "equipmentNumber") {
      setRecordData({
        ...recordData,
        // make: currentItem.maker,
        serialNumber: currentItem.makerSerialNumber,
        warrantyId: currentItem.warrantyId,
        equipmentNumber: currentItem.equipmentNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        customerNumber: currentItem.customerId,
        customerName: currentItem.customer,
      });
      setSearchSerialResults([]);
    }
  };

  // Search Customer with customer ID
  const handleCustSearch = async (searchCustfieldName, searchText) => {
    setSearchCustResults([]);
    recordData.customerNumber = searchText;
    if (searchText) {
      await customerSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchCustResults(result);
            setNoOptionsCust(false);
          } else {
            setNoOptionsCust(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // Select the customer from search result
  const handleCustSelect = (type, currentItem) => {
    setRecordData({
      ...recordData,
      // id: currentItem.id,
      customerNumber: currentItem.customerId,
      customerName: currentItem.fullName,
      // address: currentItem.addressDTO?.fullAddress,
      // city: currentItem.addressDTO?.district,
      // state: currentItem.addressDTO?.regionOrState,
      // zipCode: currentItem.addressDTO?.zipCode,
      // email: currentItem.email,
      // addressId: currentItem.addressDTO?.id,
    });
    setSearchCustResults([]);
  };

  // check general request tab input validation
  const checkGeneralRequestInputValidation = (recordObj) => {
    if (isEmptySelect(recordObj.claimType)) {
      handleSnack("error", "Request Type must not be empty.");
      return false;
    }
    return true;
  };

  // check general request tab input validation
  const checkMachineInputValidation = (recordObj) => {
    // if (!recordObj.modelNumber) {
    //     handleSnack("error", "Model must not be empty");
    //     return false;
    // } else if (recordObj.modelNumber && searchModelResults.length !== 0) {
    //     handleSnack("error", "Select model from search results.");
    //     return false;
    // } else if (recordObj.modelNumber && noOptionsModel) {
    //     handleSnack("error", "Model must be valid");
    //     return false;
    // } else if (!recordObj.serialNumber) {
    //     handleSnack("error", "Serial Number must not be empty");
    //     return false;
    // } else if (recordObj.serialNumber && searchSerialResults.length !== 0) {
    //     handleSnack("error", "Select Serial Number from search results.");
    //     return false;
    // } else if (recordObj.serialNumber && noOptionsSerial) {
    //     handleSnack("error", "Serial Number must be valid");
    //     return false;
    // }
    return true;
  };

  // check general request tab input validation
  const checkCustomerInputValidation = (recordObj) => {
    // if (!recordObj.customerNumber) {
    //     handleSnack("error", "Customer Number must not be empty");
    //     return false;
    // } else if (recordObj.customerNumber && searchCustResults.length !== 0) {
    //     handleSnack("error", "Select Customer Number from search results.");
    //     return false;
    // } else if (recordObj.customerNumber && noOptionsCust) {
    //     handleSnack("error", "Customer Number must be valid");
    //     return false;
    // }
    return true;
  };

  // click on Save and Next button
  const handleClickSaveBtn = (e) => {
    const { id } = e.target;

    const rObj = {
      ...recordData,
      claimType: recordData.claimType?.value || recordData.claimType || "EMPTY",
      claimStatus: "REGISTERED",
      payer: "CUSTOMER",
      claiment: recordData.claiment?.value || recordData.claiment || "EMPTY",
      usedFor: recordData.usedFor?.value || recordData.usedFor || "EMPTY",
      usageCondition: recordData.usageCondition?.value || recordData.usageCondition || "EMPTY",
      //   make: recordData?.make?.value || recordData.make || "",
      //   family: recordData?.family?.value || recordData.family || "",
      make: recordData.make || "",
      family: recordData.family || "",
    };
    if (id === "generalRequest" && !checkGeneralRequestInputValidation(rObj)) {
      return;
    } else if (id === "machine" && !checkMachineInputValidation(rObj)) {
      return;
    } else if (id === "customer" && !checkCustomerInputValidation(rObj)) {
      return;
    }

    if (claimRecordId) {
      const rUrl = `${CLAIM_MASTER_URL}/${claimRecordId}`;
      callPutApi(null, rUrl, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          if (id === "generalRequest") {
            setTabValue("machine");
          } else if (id === "machine") {
            setTabValue("customer");
          } else if (id === "customer") {
            handleGoBackToQurestionsModal();
          }
        }
      });
    } else {
      const rUrl = `${CLAIM_MASTER_URL}`;
      callPostApi(null, rUrl, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          handleSnack("success", `Authorization code ${responseData.authorizationCode} created successfully.`);
          setIsNewCreated(true);
          setPwaNumber(responseData.authorizationCode);
          setClaimRecordId(responseData.claimId);
          if (id === "generalRequest") {
            setTabValue("machine");
          } else if (id === "machine") {
            setTabValue("customer");
          } else if (id === "customer") {
            handleGoBackToQurestionsModal();
          }
        }
      });
    }
  };

  // General tab
  const viewGereralTabData = () => {
    return (
      <>
        <div className="row px-3 mt-0 mb-3" style={{ justifyContent: "right" }}>
          <button className="btn btn-primary text-white" onClick={() => setViewOnlyTab({ ...viewOnlyTab, customerViewOnly: false })} id="general">
            Edit
          </button>
        </div>
        <div className="card border px-3 py-2 mb-3">
          {!viewOnlyTab.gerenalViewOnly ? (
            <div className="row input-fields">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REQUEST TYPE</label>
                  <Select
                    onChange={(e) => handleSelectChange(e, "claimType")}
                    options={claimRequestTypeOptions}
                    value={recordData.claimType}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField label="REQUEST TYPE" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.claimType?.label} />
            </div>
          )}
        </div>
        <div className="card border px-3 py-2 mb-3 mt-2">
          {!viewOnlyTab.gerenalViewOnly ? (
            <div className="row input-fields">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIMENT</label>
                  {/* <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="claiment"
                                        placeholder="claiment"
                                        value={recordData.claiment}
                                        onChange={handleInputTextChange}
                                    /> */}
                  <Select
                    onChange={(e) => handleSelectChange(e, "claiment")}
                    options={authClaimentOptions}
                    value={recordData.claiment}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIMENT DETAILS</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="climentDetails"
                    placeholder="Claiment Details"
                    value={recordData.climentDetails}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">NAME OF APPROVER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="claimApprover"
                    placeholder="Name of Approver"
                    value={recordData.claimApprover}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField label="CLAIMENT" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.claiment?.label} />
              <ReadOnlyField label="CLAIMENT DETAILS" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.climentDetails} />
              <ReadOnlyField label="NAME OF APPROVER" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.claimApprover} />
            </div>
          )}
        </div>
        {!viewOnlyTab.gerenalViewOnly ? (
          <div className="row input-fields">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">REQUEST DESCRIPTION</label>
                {/* <input
                                type="text"
                                className="form-control border-radius-10 text-primary"
                                name="requestDescription"
                                placeholder="Request Description"
                                value={recordData.requestDescription}
                                onChange={handleInputTextChange}
                            /> */}
                <textarea
                  className="form-control border-radius-10 text-primary"
                  cols="30"
                  rows="4"
                  name="requestDescription"
                  placeholder="Request Description"
                  value={recordData.requestDescription}
                  onChange={handleInputTextChange}
                ></textarea>
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField label="REQUEST DESCRIPTION" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.requestDescription} />
          </div>
        )}
        <div className="card border px-3 py-2 mb-3 mt-2">
          {!viewOnlyTab.gerenalViewOnly ? (
            <div className="row input-fields">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">APPROVED ON</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        // maxDate={new Date()}
                        closeOnSelect
                        value={recordData.appoverRejectedOn}
                        onChange={(e) => handleSelectChange(e, "appoverRejectedOn")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">FAILURE DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        // maxDate={new Date()}
                        closeOnSelect
                        value={recordData.failDate}
                        onChange={(e) => handleSelectChange(e, "failDate")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        alignItems: "start",
                        marginLeft: 0,
                      }}
                      control={
                        <Switch
                          checked={recordData.replacement}
                          onChange={(e) => {
                            return;
                          }}
                          // disabled
                          // onChange={(e) =>
                          //     setRecordData({
                          //         ...recordData,
                          //         replacement: e.target.checked,
                          //     })
                          // }
                        />
                      }
                      labelPlacement="top"
                      label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField
                label="APPROVED ON"
                className="col-lg-4 col-md-4 col-sm-4 col-12"
                value={recordData.appoverRejectedOn ? <Moment format="DD/MM/YYYY">{recordData.appoverRejectedOn}</Moment> : "NA"}
              />
              <ReadOnlyField
                label="FAILURE DATE"
                className="col-lg-4 col-md-4 col-sm-4 col-12"
                value={recordData.failDate ? <Moment format="DD/MM/YYYY">{recordData.failDate}</Moment> : "NA"}
              />
              <ReadOnlyField label="REPLACEMENT" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.replacement ? "YES" : "NO"} />
            </div>
          )}
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {!viewOnlyTab?.customerViewOnly && (
            <button className="btn btn-border-primary mx-3" onClick={hideModal}>
              Cancel
            </button>
          )}
          <button className="btn btn-primary" onClick={handleClickSaveBtn} id="generalRequest">
            {!viewOnlyTab?.customerViewOnly ? "Save & Next" : "Next"}
          </button>
        </div>
      </>
    );
  };

  //  machine tab
  const viewMachineTabData = () => {
    return (
      <>
        <div className="row px-3 mt-0 mb-3" style={{ justifyContent: "right" }}>
          <button
            className="btn border-primary mx-2"
            // onClick={handleClickSaveBtn}
          >
            + Create New
          </button>
          <button className="btn btn-primary text-white" onClick={() => setViewOnlyTab({ ...viewOnlyTab, machineViewOnly: false })} id="general">
            Edit
          </button>
        </div>
        <div className="card border px-3 py-2 mb-3 mt-2">
          {!viewOnlyTab?.machineViewOnly ? (
            <div className="row input-fields">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MAKE</label>
                  {/* <Select
                    onChange={(e) => setRecordData({ ...recordData, make: e })}
                    options={makeOptions}
                    value={recordData.make}
                    styles={FONT_STYLE_SELECT}
                  /> */}
                  <SearchBox
                    value={recordData.make}
                    onChange={(e) => handleMachineSearch("maker", e.target.value)}
                    type="maker"
                    result={searchMakeResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsMake}
                    placeholder="Make"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                  <SearchBox
                    value={recordData.modelNumber}
                    onChange={(e) => handleMachineSearch("model", e.target.value)}
                    type="model"
                    placeholder="Search Model"
                    result={searchModelResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsModel}
                  />
                  {/* <div className="css-w8dmq8">*Mandatory</div> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                  <SearchBox
                    value={recordData.serialNumber}
                    onChange={(e) => handleMachineSearch("serialNo", e.target.value)}
                    type="makerSerialNumber"
                    result={searchSerialResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsSerial}
                    placeholder="Search Serial Number"
                  />
                  {/* <div className="css-w8dmq8">*Mandatory</div> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">FAMILY</label>
                  {/* <Select
                    onChange={(e) => setRecordData({ ...recordData, family: e })}
                    options={familyOptions}
                    value={recordData?.family}
                    styles={FONT_STYLE_SELECT}
                  /> */}
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="family"
                    placeholder="Auto Fill Search Model"
                    value={recordData.family}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">USED FOR</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="usedFor"
                    placeholder="Used For"
                    value={recordData.usedFor}
                    onChange={handleInputTextChange}
                  />
                  {/* <Select
                                    // onChange={(e) =>
                                    //   setRecordData({
                                    //     ...recordData,
                                    //     claimType: e,
                                    //   })
                                    // }
                                    options={claimRequestTypeOptions}
                                    // value={recordData.claimType}
                                    styles={FONT_STYLE_SELECT}
                                /> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">USAGE CONDITION</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="usageCondition"
                    placeholder="Usage Condition"
                    value={recordData.usageCondition}
                    onChange={handleInputTextChange}
                  />
                  {/* <Select
                                    // onChange={(e) =>
                                    //   setRecordData({
                                    //     ...recordData,
                                    //     claimType: e,
                                    //   })
                                    // }
                                    options={claimRequestTypeOptions}
                                    // value={recordData.claimType}
                                    styles={FONT_STYLE_SELECT}
                                /> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              {/* <ReadOnlyField label="MAKE" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.make?.label} /> */}
              <ReadOnlyField label="MAKE" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.make} />
              <ReadOnlyField label="MODEL" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.modelNumber} />
              <ReadOnlyField label="SERIAL NUMBER" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.serialNumber} />
              {/* <ReadOnlyField label="FAMILY" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.family?.label} /> */}
              <ReadOnlyField label="FAMILY" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.family} />
              <ReadOnlyField label="USED FOR" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData?.usedFor} />
              <ReadOnlyField label="USAGE CONDITION" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData?.usageCondition} />
            </div>
          )}
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {!viewOnlyTab?.machineViewOnly && (
            <button className="btn btn-border-primary mx-3" onClick={hideModal}>
              Cancel
            </button>
          )}
          <button className="btn btn-primary" onClick={handleClickSaveBtn} id="machine">
            {!viewOnlyTab?.machineViewOnly ? "Save & Next" : "Next"}
          </button>
        </div>
      </>
    );
  };

  // customer tab
  const viewCustomerTabData = () => {
    return (
      <>
        <div className="row px-3 mt-0 mb-3" style={{ justifyContent: "right" }}>
          <button className="btn btn-primary text-white" onClick={() => setViewOnlyTab({ ...viewOnlyTab, customerViewOnly: false })} id="general">
            Edit
          </button>
        </div>
        <div className="card border px-3 py-2 mb-3 mt-2">
          {!viewOnlyTab?.customerViewOnly ? (
            <div className="row input-fields">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NUMBER</label>
                  <SearchBox
                    value={recordData.customerNumber}
                    onChange={(e) => handleCustSearch("customerId", e.target.value)}
                    type="customerId"
                    result={searchCustResults}
                    onSelect={handleCustSelect}
                    noOptions={noOptionsCust}
                    placeholder="Customer Number"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="customerName"
                    placeholder="Customer Name"
                    value={recordData.customerName}
                    onChange={handleInputTextChange}
                    // disabled={true}
                  />
                  {/* <SearchBox
                                    value={recordData.customerName}
                                    // onChange={(e) =>
                                    //   handleCustSearch("customerId", e.target.value)
                                    // }
                                    type="customerId"
                                    // result={searchCustResults}
                                    // onSelect={handleCustSelect}
                                    // noOptions={noOptionsCust}
                                    disabled={true}
                                    placeholder="Customer Name"
                                /> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REFERENCE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="reference"
                    placeholder="Reference"
                    value={recordData?.reference}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <ReadOnlyField label="CUSTOMER NUMBER" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.customerNumber} />
              <ReadOnlyField label="CUSTOMER NAME" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData.customerName} />
              <ReadOnlyField label="REFERENCE" className="col-lg-4 col-md-4 col-sm-4 col-12" value={recordData?.reference} />
            </div>
          )}
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {!viewOnlyTab?.customerViewOnly && (
            <button className="btn btn-border-primary mx-3" onClick={hideModal}>
              Cancel
            </button>
          )}
          <button className="btn btn-primary" onClick={handleClickSaveBtn} id="customer">
            {!viewOnlyTab?.customerViewOnly ? "Save & Next" : "Close"}
          </button>
        </div>
      </>
    );
  };

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      {/* <Modal.Header>
                <Modal.Title class="h5 mb-0">Warranty Request</Modal.Title>
            </Modal.Header> */}
      <Modal.Body>
        <Box sx={{ typography: "body1" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList className="custom-tabs-div" aria-label="lab API tabs example" onChange={(e, newTabValue) => setTabValue(newTabValue)} centered>
                <Tab label={`GENERAL REQUEST`} value="generalRequest" />
                <Tab label={`MACHINE`} value="machine" />
                <Tab label={`CUSTOMER`} value="customer" />
              </TabList>
            </Box>
            <div className="ligt-greey-bg p-3">
              <div>
                <span className="mr-3 cursor">
                  <i className="fa fa-pencil font-size-12" aria-hidden="true"></i>
                  <span className="ml-2">Edit</span>
                </span>
                <span className={`mr-3 cursor `}>
                  <SellOutlinedIcon className=" font-size-16" />
                  <span className="ml-2"> Authorization Code({pwaNumber})</span>
                </span>
              </div>
            </div>
            <TabPanel value={tabValue}>
              {tabValue === "generalRequest" && viewGereralTabData()}
              {tabValue === "machine" && viewMachineTabData()}
              {tabValue === "customer" && viewCustomerTabData()}
            </TabPanel>
          </TabContext>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default WarrantyRequestAuthorization;
