import React, { useCallback, useEffect, useMemo, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Box, Grid, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import CustomizedSnackbar from "shared/CustomSnackBar";
import { GRID_STYLE } from "../../common/constants";

import QuickCreateModal from "../QuickCreateModal";
import ClaimDetailsModal from "./ClaimDetailsModal";
import { claimStatusOptions } from "../warrantyManagementConstants";
import WarrantyRequestCreateModal from "./WarrantyRequestCreateModal";
import ClaimReplacementDetailsModal from "../returnMaster/ClaimReplacementDetailsModal";
import WarrantyRequestAuthorization from "../warrantyMaster/WarrantyRequestAuthorization";
import NewClaimOrderRequestModal from "../claimOrderManagement/orderRequestNew/NewClaimOrderRequestModal";

import { CLAIM_ORDER_MASTER_URL, Warranty_Country_List_GET, Warranty_Evaluation_Questions_Get_GET } from "services/CONSTANTS";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { settlementValueSearchById } from "services/searchServices";
import ClaimOrderRequestModal from "../claimOrderManagement/ClaimOrderRequestModal";

const DataGridContainer = (props) => (
  <Box
    margin={"auto"}
    sx={{
      backgroundColor: "#ffffff",
      minHeight: 500,
      marginBlock: 2,
      borderRadius: 5,
      width: "95%",
      display: "flex",
      justifyContent: "center",
      padding: 2,
    }}
  >
    {props.children}
  </Box>
);

const ClaimAdministration = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [tabValue, setTabValue] = useState("claims");
  const [claimOrderExpanded, setQuoteExpanded] = useState("claimOrderStatus");
  const [claimOrderStatus, setQuoteStatus] = useState("all");

  const [pageSize, setPageSize] = useState(10);

  const [countryList, setCountryList] = useState([]);
  const [partsRecords, setPartsRecords] = useState([]);

  const [openClaimRequestModal, setOpenClaimRequestModal] = useState(false);
  const [openPartCreateModal, setOpenPartCreateModal] = useState(false);
  const [openReturnRequsterModal, setOpenReturnRequsterModal] = useState(false);

  const [openQuickCreateModal, setOpenQuickCreateModal] = useState(false);
  const [authCodeCreateModal, setAuthCodeCreateModal] = useState(false);
  const [openWarrantyWithAuthCodeReqModal, setOpenWarrantyWithAuthCodeReqModal] = useState(false);

  const [warrantyReqGenDtl, setWarrantyReqGenDtl] = useState({
    requestType: "",
    authorizationCode: "",
  });

  const [claimRecordDetail, setClaimRecordDetail] = useState(null);
  const [claimRecordData, setClaimRecordData] = useState([]);

  const [claimRecordId, setClaimRecordId] = useState(null);
  const [claimOrderId, setClaimOrderId] = useState(null);
  const [evaluationId, setEvaluationId] = useState(null);
  const [assesstmentId, setAssesstmentId] = useState(null);
  const [warrantyRecordId, setWarrantyRecordId] = useState(null);

  const [showUploadFilesModal, setShowUploadFilesModal] = useState(false);
  const [openClaimDetailsModal, setOpenClaimDetailsModal] = useState(false);
  const [openWarrantyRequestModal, setOpenWarrantyRequestModal] = useState(false);
  const [showJobHoursModal, setShowJobHoursModal] = useState(false);
  const [showClaimErrorModal, setShowClaimErrorModal] = useState(false);
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [showClaimFileUploadModal, setShowClaimFileUploadModal] = useState(false);
  const [showViewFileModal, setShowViewFileModal] = useState(false);

  const [refresh, setRefresh] = useState(0);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    if (
      !openClaimRequestModal &&
      !openPartCreateModal &&
      !openReturnRequsterModal &&
      !showFilesModal &&
      !showJobHoursModal &&
      !showClaimErrorModal &&
      !showClaimFileUploadModal &&
      !showViewFileModal
    ) {
      setClaimRecordId(null);
      setWarrantyRecordId(null);
      setClaimOrderId(null);
      setClaimRecordDetail(null);
      setRefresh((pre) => pre + 1);
    }

    if (!openReturnRequsterModal) {
      setPartsRecords([]);
    }
  }, [
    openClaimRequestModal,
    openPartCreateModal,
    openReturnRequsterModal,
    showFilesModal,
    showClaimFileUploadModal,
    showViewFileModal,
    showJobHoursModal,
    showClaimErrorModal,
  ]);

  useEffect(() => {
    getCountryKeyValueList();
  }, []);

  useEffect(() => {
    getClaimList();
  }, [claimOrderStatus, refresh]);

  // get claim list
  const getClaimList = () => {
    setIsLoading(true);
    let rUrl = CLAIM_ORDER_MASTER_URL;
    if (claimOrderStatus === "all") {
      rUrl = rUrl + `?pageNumber=${0}&pageSize=${10}&sortColumn=createdAt&orderBY=DESC`;
    } else {
      rUrl = rUrl + `/search-by-fields?field_name=claimOrderStatus&field_value=${claimOrderStatus.replace(/_/g, " ")}`;
    }
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          if (responseData.length !== 0) {
            getSettlementDetials(responseData);
            setIsLoading(false);
          } else {
            setClaimRecordData([]);
            setIsLoading(false);
          }
        } else {
          setClaimRecordData([]);
          setIsLoading(false);
        }
      },
      (error) => {
        setClaimRecordData([]);
      }
    );
  };

  // get the settlement details
  const getSettlementDetials = async (responseData) => {
    setIsLoading(true);
    const recordArr = [];
    for (let row of responseData) {
      //  const searchStr = `${CLAIM_MASTER_URL}/${row.claimValueId}`;
      if (row.settlementValueId) {
        const res = await settlementValueSearchById(row.settlementValueId);
        if (res.status === API_SUCCESS) {
          recordArr.push({
            ...row,
            settledAmount: res?.data?.totalAmountClaimed,
            settlementStatus: res?.data?.type,
          });
        } else {
          recordArr.push({
            ...row,
            settledAmount: 0,
            settlementStatus: "",
          });
        }

        if (res?.data?.type === "SETTLED") {
          handleSnack("success", `New settlement received for claim ${row.claimOrderNumber}`);
        }
      } else {
        recordArr.push({
          ...row,
          settledAmount: 0,
          settlementStatus: "",
        });
      }
    }
    setClaimRecordData(recordArr);
    setIsLoading(false);
  };

  // country key value list
  const getCountryKeyValueList = () => {
    const rUrl = `${Warranty_Country_List_GET}?pageNumber=${0}&pageSize=${10}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        const options = [];
        responseData.map((row) =>
          // options.push({ label: row.countryName, value: row.countryId })
          options.push({
            label: row.countryName,
            value: row.countryName,
          })
        );
        setCountryList(options);
      }
    });
  };

  // change tab
  const changeTab = (event, type) => {
    setTabValue(type);
  };

  // expend open|Close Claim Status Accordian
  const handleClaimOrderExpand = (panel) => (event, isExpanded) => {
    setQuoteExpanded(isExpanded ? panel : false);
  };

  // change the Claim Order Status
  const handleClaimOrderStatus = (event) => {
    setQuoteStatus(event.target.value);
  };

  // claim details model open
  const handleOpenClaimDetailsModal = (row) => {
    const _claimId = row["claimId"];
    if (_claimId) {
      setClaimRecordId(_claimId);
    }

    const _warrantyId = row["warrantyId"];
    if (_warrantyId) {
      setWarrantyRecordId(_warrantyId);
    }

    const _claimOrderId = row["claimOrderId"];
    if (_claimOrderId) {
      setClaimOrderId(_claimOrderId);
    }

    const _evaluationId = row["evaluationId"];
    if (_evaluationId) {
      setEvaluationId(_evaluationId);
    }

    const _assessmentId = row["assessmentId"];
    if (_assessmentId) {
      setAssesstmentId(_assessmentId);
    }

    // if (_claimId) {
    //     callGetApi(null, `${CLAIM_MASTER_URL}/${_claimId}`, (response) => {
    //         if (response.status === API_SUCCESS) {
    //             const responseData = response.data;
    //             setClaimRecordDetail(responseData);
    //         }
    //     });
    // }
    setOpenClaimRequestModal(true);
  };

  // claim order/Request model show hide
  const handleOpenClaimRequestModal = () => {
    setOpenClaimDetailsModal(false);
    setOpenClaimRequestModal(true);
  };

  // show part create modal
  const handleShowPartCreateModal = () => {
    setOpenClaimRequestModal(!openClaimRequestModal);
    setOpenPartCreateModal(!openPartCreateModal);
  };

  // opem return requester model box
  const handleShowReturnRequetrModal = (row = null) => {
    if (row) {
      setPartsRecords([row]);
    } else {
      setPartsRecords([]);
    }
    setOpenClaimRequestModal(!openClaimRequestModal);
    setOpenReturnRequsterModal(!openReturnRequsterModal);
  };

  // open warranty request model from quick create modal
  const handleOpenWarrantyReqModal = () => {
    setOpenQuickCreateModal(false);
    setOpenWarrantyWithAuthCodeReqModal(true);
  };

  // authorization Code create Modal show and Hide
  const handleOpenCloseAuthCodeModal = () => {
    setOpenQuickCreateModal(!openQuickCreateModal);
    setAuthCodeCreateModal(!authCodeCreateModal);
  };

  // quicl Create >> Claim Order Create Modal Show|Hide
  const handleShowClaimOrderDetialsModal = () => {
    setOpenClaimRequestModal(true);
    setOpenQuickCreateModal(false);
  };

  const handleShowRequestModal = () => {
    setOpenWarrantyWithAuthCodeReqModal(false);
  };

  // show job hours modal box
  const handleShowOrderJobHoursModal = () => {
    setShowJobHoursModal(!showJobHoursModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim errors modal box
  const handleShowClaimErrorsModal = () => {
    setShowClaimErrorModal(!showClaimErrorModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim Files modal box
  const handleShowFilesModal = () => {
    setShowFilesModal(!showFilesModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim Files upload modal box
  const handleShowClaimFileUploadModal = () => {
    setShowFilesModal(!showFilesModal);
    setShowClaimFileUploadModal(!showClaimFileUploadModal);
  };

  // show view files(image modal)
  const handleShowViewFileModal = () => {
    setShowViewFileModal(!showViewFileModal);
    setShowFilesModal(!showFilesModal);
  };

  const claimColumn = useMemo(
    () => [
      {
        field: "claimOrderNumber",
        // field: "claimId",
        // field: "claimOrderId",
        headerName: "Claim Number",
        flex: 1,
      },
      {
        field: "claimRequestDate",
        headerName: "Submitted On",
        flex: 1,
      },
      // {
      //     field: "submittedBy",
      //     headerName: "Submitted By",
      //     flex: 1,
      //     renderCell: ({ row }) => "User",
      // },
      {
        field: "claimOrderStatus",
        headerName: "Claim Status",
        flex: 1,
        // renderCell: ({ row }) => <>REGISTERED</>,
      },
      {
        field: "totalAmountClaimed",
        headerName: "Claim Value",
        flex: 1,
        renderCell: ({ row }) => <>{(row.totalAmountClaimed || 0).toFixed(2)}</>,
      },
      {
        field: "totalAmountAllowed",
        headerName: "Settled Amount",
        flex: 1,
        renderCell: ({ row }) => <>{(row.totalAmountAllowed || 0).toFixed(2)}</>,
      },
      {
        // field: "settlementStatus",
        headerName: "Settlement Status",
        flex: 1,
        renderCell: ({ row }) => (
          <>
            {row?.claimOrderStatus === "REGISTERED" ||
            row?.claimOrderStatus === "CLAIM_SUBMITTED" ||
            row?.claimOrderStatus === "CLAIM_ACKNOWLEDGED" ||
            row?.claimOrderStatus === "CLAIM_ACCEPTED" ||
            row?.claimOrderStatus === "CONTESTED"
              ? "Settlement Pending"
              : row?.claimOrderStatus === "CLAIM_REJECTED"
              ? "Claim Rejected"
              : row?.claimOrderStatus === "SETTLED"
              ? "Settlement Received"
              : row?.claimOrderStatus === "ARCHIVED" || row?.claimOrderStatus === "CLOSED"
              ? "Settlement Closed"
              : ""}
          </>
        ),
      },
      // {
      //     field: "claimType",
      //     headerName: "Claim Type",
      //     flex: 1,
      // },
      // {
      //     field: "rmaNumber",
      //     headerName: "RMA Number",
      //     flex: 1,
      // },
      // {
      //     field: "claimRequestDate",
      //     // field: "createdOn",
      //     headerName: "Claim Date",
      //     flex: 1,
      // },
      {
        field: "action",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ row }) => {
          return [
            <GridActionsCellItem
              icon={
                <div className=" cursor" onClick={() => handleOpenClaimDetailsModal(row)}>
                  <Tooltip title="Claim Process">
                    {/* <CachedIcon /> */}
                    <EditOutlinedIcon />
                  </Tooltip>
                </div>
              }
              label="Edit"
              className="textPrimary"
              color="inherit"
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div>
        <div className="content-body bg-white" style={{ minHeight: "884px" }}>
          <div className="container-fluid mt-3">
            <h4 className="font-weight-600 mb-0 mt-4">My Claims</h4>
            <Grid container sx={{ width: "100%", backgroundColor: "#f3eafe", borderRadius: 5, marginBlock: 2 }}>
              <Grid item xs={12}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 3, marginInline: 5 }}>
                    <TabList className="" onChange={changeTab}>
                      <Tab label="CLAIM REQUESTS" value={"claims"} className="heading-tabs" />
                    </TabList>
                  </Box>
                  <TabPanel value="claims" sx={{ marginTop: 0 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <Box sx={{ marginBlock: 2 }}>
                          <Accordion
                            //   sx={{ backgroundColor: "#f3eafe" }}
                            defaultExpanded
                            expanded={claimOrderExpanded === "claimOrderStatus"}
                            onChange={handleClaimOrderExpand("claimOrderStatus")}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Claim Status</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <RadioGroup value={claimOrderStatus} onChange={handleClaimOrderStatus}>
                                <FormControl>
                                  <FormControlLabel label={<Typography sx={{ fontSize: 14 }}>All</Typography>} value={"all"} control={<Radio />} />
                                  {claimStatusOptions.map((statusType) => {
                                    if (
                                      statusType?.value === "REGISTERED" ||
                                      statusType?.value === "CLAIM_SUBMITTED" ||
                                      statusType?.value === "SETTLED" ||
                                      statusType?.value === "CLAIM_REJECTED" ||
                                      statusType?.value === "CLOSED"
                                    ) {
                                      return (
                                        <FormControlLabel
                                          label={<Typography sx={{ fontSize: 14 }}>{statusType?.label}</Typography>}
                                          value={statusType?.value}
                                          control={<Radio />}
                                        />
                                      );
                                    }
                                  })}
                                </FormControl>
                              </RadioGroup>
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={10} container>
                        <DataGridContainer>
                          <DataGrid
                            loading={isLoading}
                            sx={GRID_STYLE}
                            getRowId={(row) => row.claimOrderId}
                            // getRowId={(row) => row.claimId}
                            rows={claimRecordData}
                            columns={claimColumn}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            autoHeight
                          />
                        </DataGridContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      {openClaimDetailsModal && (
        <ClaimDetailsModal
          show={openClaimDetailsModal}
          hideModal={() => setOpenClaimDetailsModal(false)}
          recordId={claimRecordId}
          handleSnack={handleSnack}
          handleOpenClaimRequestModal={handleOpenClaimRequestModal}
        />
      )}

      {/* {(openClaimRequestModal ||
        openPartCreateModal ||
        showJobHoursModal ||
        showClaimErrorModal ||
        showFilesModal ||
        showClaimFileUploadModal ||
        showViewFileModal ||
        openReturnRequsterModal) && (
        <ClaimOrderRequestModal
          show={openClaimRequestModal}
          hideModal={() => setOpenClaimRequestModal(false)}
          handleSnack={handleSnack}
          claimRecordId={claimRecordId}
          setClaimRecordId={setClaimRecordId}
          claimOrderId={claimOrderId}
          setClaimOrderId={setClaimOrderId}
          assesstmentId={assesstmentId}
          setAssesstmentId={setAssesstmentId}
          evaluationId={evaluationId}
          setEvaluationId={setEvaluationId}
          showJobHoursModal={showJobHoursModal}
          handleShowOrderJobHoursModal={handleShowOrderJobHoursModal}
          showClaimErrorModal={showClaimErrorModal}
          handleShowClaimErrorsModal={handleShowClaimErrorsModal}
          showFilesModal={showFilesModal}
          handleShowFilesModal={handleShowFilesModal}
          showClaimFileUploadModal={showClaimFileUploadModal}
          handleShowClaimFileUploadModal={handleShowClaimFileUploadModal}
          showViewFileModal={showViewFileModal}
          handleShowViewFileModal={handleShowViewFileModal}
          handleShowReturnRequetrModal={handleShowReturnRequetrModal}
          openReturnRequsterModal={openReturnRequsterModal}
          partsRecords={partsRecords}
          countryOptions={countryList}
          byAuthCode={false}
        />
      )} */}
      {(openClaimRequestModal ||
        openPartCreateModal ||
        showJobHoursModal ||
        showClaimErrorModal ||
        showFilesModal ||
        showClaimFileUploadModal ||
        showViewFileModal ||
        openReturnRequsterModal) && (
        <NewClaimOrderRequestModal
          show={openClaimRequestModal}
          hideModal={() => setOpenClaimRequestModal(false)}
          handleSnack={handleSnack}
          claimRecordId={claimRecordId}
          setClaimRecordId={setClaimRecordId}
          claimOrderId={claimOrderId}
          setClaimOrderId={setClaimOrderId}
          assesstmentId={assesstmentId}
          setAssesstmentId={setAssesstmentId}
          evaluationId={evaluationId}
          setEvaluationId={setEvaluationId}
          showJobHoursModal={showJobHoursModal}
          handleShowOrderJobHoursModal={handleShowOrderJobHoursModal}
          showClaimErrorModal={showClaimErrorModal}
          handleShowClaimErrorsModal={handleShowClaimErrorsModal}
          showFilesModal={showFilesModal}
          handleShowFilesModal={handleShowFilesModal}
          showClaimFileUploadModal={showClaimFileUploadModal}
          handleShowClaimFileUploadModal={handleShowClaimFileUploadModal}
          showViewFileModal={showViewFileModal}
          handleShowViewFileModal={handleShowViewFileModal}
          handleShowReturnRequetrModal={handleShowReturnRequetrModal}
          openReturnRequsterModal={openReturnRequsterModal}
          partsRecords={partsRecords}
          countryOptions={countryList}
          byAuthCode={false}
        />
      )}

      {openWarrantyRequestModal && (
        <WarrantyRequestCreateModal show={openWarrantyRequestModal} hideModal={() => setOpenWarrantyRequestModal(false)} handleSnack={handleSnack} />
      )}

      {openQuickCreateModal && (
        <QuickCreateModal
          show={openQuickCreateModal}
          hideModal={() => setOpenQuickCreateModal(false)}
          handleSnack={handleSnack}
          handleOpenWarrantyReqModal={handleOpenWarrantyReqModal}
          setWarrantyReqGenDtl={setWarrantyReqGenDtl}
          handleOpenCloseAuthCodeModal={handleOpenCloseAuthCodeModal}
          setClaimOrderId={setClaimOrderId}
          setAssesstmentId={setAssesstmentId}
          setEvaluationId={setEvaluationId}
          setWarrantyRecordId={setWarrantyRecordId}
          setClaimRecordDetail={setClaimRecordDetail}
          setClaimRecordId={setClaimRecordId}
          handleShowClaimOrderDetialsModal={handleShowClaimOrderDetialsModal}
        />
      )}
      {openWarrantyWithAuthCodeReqModal && (
        <ClaimReplacementDetailsModal
          show={openWarrantyWithAuthCodeReqModal}
          hideModal={() => setOpenWarrantyWithAuthCodeReqModal(false)}
          handleSnack={handleSnack}
          warrantyReqGenDtl={warrantyReqGenDtl}
          handleShowRequestModal={handleShowRequestModal}
        />
      )}

      {authCodeCreateModal && (
        <WarrantyRequestAuthorization
          show={authCodeCreateModal}
          // hideModal={handleCloseWarrantyReqDtlModal}
          hideModal={handleOpenCloseAuthCodeModal}
          handleSnack={handleSnack}
          pwaNumber={""}
          warrantyRequestType={warrantyReqGenDtl.requestType}
          claimRecordId={claimRecordId}
          setClaimRecordId={setClaimRecordId}
          handleGoBackToQurestionsModal={handleOpenCloseAuthCodeModal}
          claimRecordDetail={claimRecordDetail}
        />
      )}
    </>
  );
};

export default ClaimAdministration;
