import React, { useContext, useState } from "react";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import { FONT_STYLE_SELECT } from "../common/constants";
import {
    claimRequestObj,
    claimRequestTypeOptions,
    questionsOptions,
    warrantyRequestObj,
} from "./warrantyManagementConstants";
import { ReadOnlyField } from "../common/ReadOnlyField";
import { AuthContext } from "../../context/AuthContext";
import SearchBox from "../common/SearchBox";
import { claimSearchByField } from "../../services/searchServices";
import { callGetApi, callPostApi, callPutApi } from "../../services/ApiCaller";
import { urPK } from "@mui/x-date-pickers";
import { API_SUCCESS } from "../../services/ResponseCode";
import {
    CLAIM_BY_AUTH_CODE_GET,
    CLAIM_MASTER_URL,
    CLAIM_ORDER_MASTER_URL,
    WARRANTY_MASTER_URL,
} from "../../services/CONSTANTS";
import { error } from "jquery";

const QuickCreateModal = ({
    show,
    hideModal,
    handleSnack,
    handleOpenWarrantyReqModal,
    setWarrantyReqGenDtl,
    handleOpenCloseAuthCodeModal,
    setClaimOrderId,
    setAssesstmentId,
    setEvaluationId,
    setWarrantyRecordId,
    setClaimRecordDetail,
    setClaimRecordId,
    handleShowClaimOrderDetialsModal,
}) => {
    const { auth } = useContext(AuthContext);
    const [recordData, setRecordData] = useState({
        requesterDetails: "",
        requestType: "",
        replacement: "",
        description: "",
        reference: "",
    });
    const [searchAuthResults, setSearchAuthResults] = useState([]);
    const [noOptionsAuth, setNoOptionsAuth] = useState(false);
    const [isValidAuthCode, setIsValidAuthCode] = useState(false);
    const [validClaimResponse, setValidClaimResponse] = useState(null);

    // input field text change
    const handleInputTextChange = (e) => {
        const { name, value } = e.target;
        if (name === "reference") {
            setIsValidAuthCode(false);
            setValidClaimResponse(null);
        }
        setRecordData({ ...recordData, [name]: value });
    };

    // Search Claim with Authorization Code >> check authorization Code
    const handleAuthSearch = async (searchfieldName, searchText) => {
        setSearchAuthResults([]);
        recordData.reference = searchText;
        if (searchText) {
            await claimSearchByField(
                `field_name=${searchfieldName}&field_value='${searchText}'`
            )
                .then((result) => {
                    if (result && result.length > 0) {
                        setSearchAuthResults(result);
                        setNoOptionsAuth(false);
                    } else {
                        setNoOptionsAuth(true);
                    }
                })
                .catch((e) => {
                    handleSnack(
                        "error",
                        "Error occurred while searching the customer!"
                    );
                });
        }
    };

    // Select the authrozation Code from search result
    const handleAutSelect = (type, currentItem) => {
        setRecordData({
            ...recordData,
            reference: currentItem.authorizationCode,
        });
        setSearchAuthResults([]);
    };

    // check autorization code is valid or not
    const handleCheckAuthCode = async () => {
        if (!recordData.reference) {
            handleSnack(
                "info",
                "Please enter authorization code first then you can check."
            );
            return;
        }
        const rUrl = `${CLAIM_BY_AUTH_CODE_GET}${recordData.reference}`;
        callGetApi(
            null,
            rUrl,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    handleSnack(
                        "success",
                        `Authorization Code-${recordData.reference} is valid`
                    );
                    setIsValidAuthCode(true);
                    setValidClaimResponse(responseData);
                } else {
                    handleSnack("info", response.data?.message);
                    setIsValidAuthCode(false);
                    setValidClaimResponse(null);
                }
            },
            (error) => {
                handleSnack(
                    "error",
                    `Claim for Authorization Code-${recordData.reference} is not found.`
                );
                setIsValidAuthCode(false);
                setValidClaimResponse(null);
            }
        );
    };

    // check input validation
    const checkInputValidation = (recordObj) => {
        if (!recordObj.requestType) {
            handleSnack("info", "Claim Request Type must not be empty.");
            return false;
        } else if (
            (recordObj.requestType === "PWA" ||
                recordObj.requestType === "GOODWILL" ||
                recordObj.requestType === "LATE_CLAIM") &&
            !recordObj.reference
        ) {
            handleSnack(
                "info",
                "Warranty Authorization code must not be empty."
            );
            return false;
        } else if (recordObj.reference && !isValidAuthCode) {
            handleSnack(
                "info",
                "Warranty Authorization code must be valid, Click on Check button and verify."
            );
            return false;
        }
        return true;
    };

    // Warranty Create Modal Box
    const handleWarrantyCreate = async () => {
        return new Promise((resolve, reject) => {
            try {
                const rObj = {
                    ...warrantyRequestObj,
                    unit: "MONTHS",
                    warrantyStatus: "IN_WARRANTY",
                    claimId: validClaimResponse?.claimId || 0,
                };
                const warrantyRUrl = WARRANTY_MASTER_URL;
                callPostApi(
                    null,
                    warrantyRUrl,
                    rObj,
                    (response) => {
                        if (response.status === API_SUCCESS) {
                            const responseData = response.data;
                            resolve({
                                success: true,
                                warrantyResult: responseData,
                            });
                        } else {
                            resolve({
                                success: false,
                                // warrantyResult: responseData,
                            });
                        }
                    },
                    (error) => {
                        resolve({
                            success: false,
                        });
                    }
                );
            } catch (error) {
                resolve({
                    success: false,
                });
            }
        });
    };

    // Create Warranty Order/Request
    const handleCreateClaimOrder = async () => {
        return new Promise((resolve, reject) => {
            try {
                const rObj = {
                    ...claimRequestObj,
                    claimNumber: Math.floor(Math.random() * 9000) + 1000,
                    // claimId: validClaimResponse?.claimId,
                };
                const claimOrderReqUrl = CLAIM_ORDER_MASTER_URL;
                callPostApi(
                    null,
                    claimOrderReqUrl,
                    rObj,
                    (response) => {
                        if (response.status === API_SUCCESS) {
                            const responseData = response.data;
                            resolve({
                                success: true,
                                claimOrderResult: responseData,
                            });
                        } else {
                            resolve({
                                success: false,
                            });
                        }
                    },
                    (error) => {
                        resolve({
                            success: false,
                        });
                    }
                );
            } catch (error) {
                resolve({
                    success: false,
                });
            }
        });
    };

    // Update Claim
    const handleUpdateClaim = async (resultObj) => {
        return new Promise((resolve, reject) => {
            try {
                const rObj = {
                    ...validClaimResponse,
                    claimNumber: resultObj?.claimNumber,
                    claimOrderId: resultObj?.claimOrderId,
                };
                const claimReqUrl = `${CLAIM_MASTER_URL}/${validClaimResponse?.claimId}`;
                callPutApi(
                    null,
                    claimReqUrl,
                    rObj,
                    (response) => {
                        if (response.status === API_SUCCESS) {
                            const responseData = response.data;
                            resolve({
                                success: true,
                                claimResult: responseData,
                            });
                        } else {
                            resolve({
                                success: false,
                            });
                        }
                    },
                    (error) => {
                        resolve({
                            success: false,
                        });
                    }
                );
            } catch (error) {
                resolve({
                    success: false,
                });
            }
        });
    };

    // claim order create and claim update
    const handleClaimAndOrderAddUpdate = () => {
        handleCreateClaimOrder().then((res) => {
            if (res.success) {
                const result = res.claimOrderResult;
                handleUpdateClaim(result).then((res) => {
                    if (res.success) {
                        const result = res.claimResult;
                        setClaimRecordId(result.claimId);
                        setClaimOrderId(result.claimOrderId);
                        setAssesstmentId(result.assessmentId);
                        setEvaluationId(result.evaluationId);
                        setWarrantyRecordId(result.warrantyId);
                        setClaimRecordDetail(result);
                        handleShowClaimOrderDetialsModal();
                    }
                });
            }
        });
    };

    // click on create Button
    const handleCreate = () => {
        const rObj = {
            ...recordData,
            requestType: recordData.requestType?.value || "",
            replacement: recordData.replacement?.value || "",
        };
        if (!checkInputValidation(rObj)) {
            return;
        }
        if (rObj.requestType === "STANDARD") {
            handleWarrantyCreate().then((res) => {
                if (res.success) {
                    handleClaimAndOrderAddUpdate();
                } else {
                }
            });
        } else {
            handleClaimAndOrderAddUpdate();
        }

        // handleOpenWarrantyReqModal();
    };

    return (
        <Modal show={show} onHide={hideModal} size="md">
            <Modal.Header className="d-block mb-0 pb-0">
                <Modal.Title className="h5">Quick Create</Modal.Title>
                <p>
                    Create a claim request, if you have PWA (Pre Warranty
                    Authorization), please select claim type as PWA and input
                    the PWA code
                </p>
            </Modal.Header>
            <Modal.Body>
                <div className="row input-fields">
                    <ReadOnlyField
                        label="PARTNER ID"
                        className="col-lg-12 col-md-12 col-sm-12 col-12"
                        value={
                            auth &&
                            `${
                                auth?.customerId ? `${auth?.customerId} - ` : ""
                            }${auth?.customerName ? auth?.customerName : ""}`
                        }
                        // value="706890-Rheem Partner 5"
                    />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                            <label className="text-light-dark font-size-12 font-weight-500">
                                ENTER REQUESTER DETAILS HERE
                            </label>
                            <input
                                type="text"
                                className="form-control border-radius-10 text-primary"
                                name="requesterDetails"
                                placeholder="Partner Details"
                                value={recordData.requesterDetails}
                                onChange={handleInputTextChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                            <label className="text-light-dark font-size-12 font-weight-500">
                                CLAIM REQUEST TYPE
                            </label>
                            <Select
                                onChange={(e) => {
                                    setRecordData({
                                        ...recordData,
                                        requestType: e,
                                    });
                                    setWarrantyReqGenDtl((pre) => ({
                                        ...pre,
                                        requestType: e,
                                    }));
                                }}
                                options={claimRequestTypeOptions}
                                value={recordData.requestType}
                                styles={FONT_STYLE_SELECT}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                            <label className="text-light-dark font-size-12 font-weight-500">
                                REPLACEMENT
                            </label>
                            <Select
                                onChange={(e) =>
                                    setRecordData({
                                        ...recordData,
                                        replacement: e,
                                    })
                                }
                                options={questionsOptions}
                                value={recordData.replacement}
                                styles={FONT_STYLE_SELECT}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                            <label className="text-light-dark font-size-12 font-weight-500">
                                DESCRIPTION
                            </label>
                            <textarea
                                cols="30"
                                rows="4"
                                className="form-control border-radius-10 text-primary"
                                placeholder="Description"
                                name="description"
                                value={recordData.description}
                                onChange={handleInputTextChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                            <label className="text-light-dark font-size-12 font-weight-500">
                                REFERENCE
                            </label>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    value={recordData.reference}
                                    autoComplete="off"
                                    name="reference"
                                    onChange={handleInputTextChange}
                                    className="form-control text-primary"
                                    placeholder="Warranty Authorization Code"
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn bg-primary text-white"
                                        type="button"
                                        onClick={handleCheckAuthCode}
                                    >
                                        Check
                                    </button>
                                </div>
                            </div>
                            {/* <SearchBox
                                value={recordData.reference}
                                onChange={(e) =>
                                    handleAuthSearch(
                                        "authorizationCode",
                                        e.target.value
                                    )
                                }
                                type="authorizationCode"
                                result={searchAuthResults}
                                // onSelect={handleAuthSelect}
                                noOptions={noOptionsAuth}
                                placeholder="Warranty Autorization Code"
                            /> */}
                            {/* <input
                type="text"
                className="form-control border-radius-10 text-primary"
                name="reference"
                placeholder="Warranty Autorization Code"
                value={recordData.reference}
                onChange={handleInputTextChange}
              /> */}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-border-primary w-100"
                    onClick={hideModal}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary w-100"
                    onClick={handleCreate}
                >
                    Create
                </button>
                <button
                    className="btn btn-primary w-100"
                    // disabled={recordData.reference && isValidAuthCode}
                    onClick={handleOpenCloseAuthCodeModal}
                >
                    Create New Auth Code
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuickCreateModal;
