import React, { useState } from "react";

import { FactCheck } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import CachedIcon from "@mui/icons-material/Cached";
import MemoryIcon from "@mui/icons-material/Memory";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import { Checkbox, FormControlLabel, Grid, Menu, MenuItem, Typography } from "@mui/material";

import { Modal } from "react-bootstrap";
import UploadFilesModal from "./warrantyManagement/UploadFilesModal";

const RegisterProductModal = ({
  show,
  hideModal,
  handleSnack,
  fileUploadForProduct,
  handleOpenProductFileUploadModal,
  handleCloseProductFileUploadModal,
  handleOpenEquCreateModal,
}) => {
  const [textValue, setTextValue] = useState("");
  const [faqEl, setFAQEl] = useState(null);

  const [webFaqE1, setWebFaqE1] = useState(null);
  const [selectedWeb, setSelectedWeb] = useState("All Web");
  const [selectedCardText, setSelectedCardText] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);

  const handleClickFAQ = (event) => {
    setFAQEl(event.currentTarget);
  };

  const handleCloseFAQ = (value) => {
    setFAQEl(null);
  };

  const handleSelectCard = (selectText, cardNo) => {
    setSelectedCardText(selectText);
    setSelectedCard(cardNo);
    setTextValue(selectText);
  };

  const handleRefreshPrompts = () => {
    setSelectedCardText("");
    setSelectedCard(null);
  };

  const handleClickWebFAQ = (event) => {
    setWebFaqE1(event.currentTarget);
  };

  const handleCloseWebFAQ = (value) => {
    setWebFaqE1(null);
  };

  const handleSelectWebType = (webType) => {
    setSelectedWeb(webType);
    handleCloseWebFAQ(null);
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body style={{ backgroundColor: "#E5E5E5" }}>
          <div className=" p-4" style={{ backgroundColor: "#E5E5E5" }}>
            <Typography variant="h4" component="p">
              Hi there, <span className="bg-blue-white-gradient">Ashok</span>
            </Typography>
            <Typography variant="h4" component="p">
              What <span className="bg-blue-white-gradient">would you like to know?</span>
            </Typography>
            <Typography variant="h6">Use one of the most common prompts</Typography>
            <Typography variant="h6">Or use your own to begin</Typography>
          </div>
          <div className="mt-3">
            <Grid container className="px-4" spacing={2}>
              <Grid item xs={3}>
                <div
                  className={`card border bg-grey px-3 py-2 text-primary cursor register-products-card ${
                    selectedCard === 1 ? "register-products-active-card" : ""
                  }`}
                  style={{ minHeight: "125px" }}
                  onClick={() => handleSelectCard("Upload your sales invoice/warranty card to register the warranty", 1)}
                >
                  <div className="font-weight-bold font-size-14">
                    <strong>Upload your sales invoice/warranty card to register the warranty</strong>
                    <p className="mb-0 mt-4" style={{ position: "absolute", bottom: "10px", left: "10px" }}>
                      <FactCheck fontSize="medium" />
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className={`card border bg-grey px-3 py-2 text-primary cursor register-products-card ${
                    selectedCard === 2 ? "register-products-active-card" : ""
                  }`}
                  style={{ minHeight: "125px" }}
                  onClick={() => handleSelectCard("Upload your service report to create a warranty claim request", 2)}
                >
                  <div className="font-weight-bold font-size-14">
                    <strong>Upload your service report to create a warranty claim request</strong>
                    <p className="mb-0 mt-4" style={{ position: "absolute", bottom: "10px", left: "10px" }}>
                      <MailOutlineIcon fontSize="medium" />
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className={`card border bg-grey px-3 py-2 text-primary cursor register-products-card ${
                    selectedCard === 3 ? "register-products-active-card" : ""
                  }`}
                  style={{ minHeight: "125px" }}
                  onClick={() => handleSelectCard("Summerise product information for me in one paragraph", 3)}
                >
                  <div className="font-weight-bold font-size-14">
                    <strong>Summerise product information for me in one paragraph</strong>
                    <p className="mb-0 mt-4" style={{ position: "absolute", bottom: "10px", left: "10px" }}>
                      <ChatBubbleOutlineIcon fontSize="medium" />
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  className={`card border bg-grey px-3 py-2 text-primary cursor register-products-card ${
                    selectedCard === 4 ? "register-products-active-card" : ""
                  }`}
                  style={{ minHeight: "125px" }}
                  onClick={() => handleSelectCard("Upload your sales invoice to create an installation request", 4)}
                >
                  <div className="font-weight-bold font-size-14">
                    <strong>Upload your sales invoice to create an installation request</strong>
                    <p className="mb-0 mt-4" style={{ position: "absolute", bottom: "10px", left: "10px" }}>
                      <MemoryIcon fontSize="medium" />
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="font-size-16 px-4 cursor" onClick={handleRefreshPrompts}>
            <CachedIcon sx={{ fontSize: "28px" }} />
            <span className="m-1">Refresh Prompts</span>
          </div>
          <div className="px-4">
            <div className="card border px-2 mt-3">
              <div className="d-flex justify-content-between align-items-center py-2">
                <div className="h5 font-size-14 ">
                  {textValue.length === 0 ? "Ask whatever you want..." : ""}
                  {/* {selectedCardText || "Ask whatever you want..."} */}
                </div>
                <div className="d-flex align-items-center text-primary p-2 bg-grey rounded-pill ml-2 cursor" onClick={handleClickWebFAQ}>
                  <LanguageOutlinedIcon />
                  {selectedWeb} <ArrowDropDownIcon />
                </div>
                <Menu id="simple-menu" anchorEl={webFaqE1} keepMounted open={Boolean(webFaqE1)} onClose={() => handleCloseWebFAQ(null)}>
                  <MenuItem onClick={() => handleSelectWebType("All Web")}>All Web</MenuItem>
                  <MenuItem onClick={() => handleSelectWebType("Service CPQ")}>Service CPQ</MenuItem>
                  {/* <MenuItem>SAP</MenuItem> */}
                </Menu>
              </div>
              <textarea
                name="textValue"
                id=""
                cols={3}
                rows={6}
                value={textValue}
                className="text-primary border-0 mb-3"
                onChange={(e) => setTextValue(e.target.value)}
              ></textarea>
              <div className="d-flex justify-content-between align-items-center mb-2 ">
                <div className="d-flex align-items-center">
                  <div>
                    {/* <Button aria-controls="simple-menu" sx={{ color: "#000000" }} aria-haspopup="true" onClick={handleClickFAQ}>
                    <AddCircleIcon /> Add Attachment <KeyboardArrowDownIcon className="small" />
                  </Button>*/}
                    <div className="cursor" onClick={handleClickFAQ}>
                      <AddCircleIcon /> Add Attachment
                    </div>
                    <Menu id="simple-menu" anchorEl={faqEl} keepMounted open={Boolean(faqEl)} onClose={() => handleCloseFAQ(null)}>
                      <MenuItem>
                        <FormControlLabel sx={{ mb: 0, py: 0, px: 0.5 }} control={<Checkbox />} label="Sales Invoice" />
                      </MenuItem>
                      <MenuItem>
                        <FormControlLabel sx={{ mb: 0, py: 0, px: 0.5 }} control={<Checkbox />} label="Service Report" />
                      </MenuItem>
                      <MenuItem>
                        <FormControlLabel sx={{ mb: 0, py: 0, px: 0.5 }} control={<Checkbox />} label="Failure Report" />
                      </MenuItem>
                      <MenuItem>
                        <FormControlLabel sx={{ mb: 0, py: 0, px: 0.5 }} control={<Checkbox />} label="Warranty Card" />
                      </MenuItem>
                    </Menu>
                  </div>
                  <span className="ml-3 cursor">
                    <ImageIcon /> Use Image
                  </span>
                </div>
                <div className="d-flex align-items-center text-dark font-weight-bold font-size-14">
                  {`${textValue.length}/20000`}
                  <div
                    className="d-flex justify-content-center align-items-center text-white p-2 bg-primary mx-2 rounded cursor"
                    style={{ width: "24px", height: "24px" }}
                    onClick={handleOpenProductFileUploadModal}
                  >
                    <ArrowForwardIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {fileUploadForProduct && (
        <UploadFilesModal
          show={fileUploadForProduct}
          hideModal={handleCloseProductFileUploadModal}
          uploadFile={true}
          showUplodScreen={handleOpenEquCreateModal}
        />
      )}
    </>
  );
};

export default RegisterProductModal;
