import axios from "axios";
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import Cookies from "js-cookie";
import {
    CLAIM_MASTER_URL,
    CLAIM_VALUE_MASTER_URL,
    CLIAM_SEARCH_BY_FIELDS_URL,
    PRICING_LABOUR_PRICE_SEARCH,
    SEARCH_CONSUMABLE,
    SEARCH_CUSTOMER,
    SEARCH_EQUIPMENT,
    SEARCH_EXTWORK,
    SEARCH_MACHINE,
    SEARCH_SPARC_PART,
    SEARCH_SPAREPART,
    SETTLEMENT_VALUE_MASTER_URL,
} from "./CONSTANTS";

/* ----------------- Authorization ------------------- */

export function getAccessToken() {
    let storedCookie = Cookies.get("logincustomerinfo");
    let getCookiesJsonData;
    if (storedCookie != undefined) {
        getCookiesJsonData = JSON.parse(storedCookie);
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `${getCookiesJsonData?.access_token}`,
        },
    };
    return config;
}

/* ------------------------------------------------------------ */

//Search Customer based on the search criteria to fill the header
export const customerSearch = (searchStr) => {
    console.log("RepairBuilder > customerSearch called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SEARCH_CUSTOMER(`${searchStr}`), getAccessToken())
                .then((res) => {
                    console.log("customerSearch > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject("Error occurred while fetching customer data");
                })
                .catch((err) => {
                    console.log("customerSearch > axios err=", err);
                    reject("Error in customerSearch axios!");
                });
        } catch (error) {
            console.error("in RepairBuilder > customerSearch, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

//Search machine from equipment master based on the search criteria
export const machineSearch = (searchStr) => {
    console.log("RepairBuilder > machineSearch called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SEARCH_MACHINE(searchStr), getAccessToken())
                .then((res) => {
                    console.log("machineSearch > axios res=", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("machineSearch > axios err=", err);
                    reject("Error in itemSearch axios!");
                });
        } catch (error) {
            console.error("in RepairBuilder > machineSearch, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

//Search Spare Part based on the search criteria
export const sparePartSearch = async (searchStr) => {
    console.log("RepairBuilder > sparePartSearch called...");
    return new Promise(async (resolve, reject) => {
        try {
            await axios
                .get(SEARCH_SPAREPART(searchStr), getAccessToken())
                .then((res) => {
                    console.log("sparePartSearch > axios res=", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        console.log("Status:", res.status);
                        reject("Error in Search Sparepart axios!");
                    }
                })
                .catch((err) => {
                    console.log("sparePartSearch > axios err=", err);
                    reject("Error in itemSearch axios!");
                });
        } catch (error) {
            console.error("in RepairBuilder > sparePartSearch, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getConsumables = (query) => {
    console.log("SearchService > getConsumables called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SEARCH_CONSUMABLE(query), getAccessToken())
                .then((res) => {
                    console.log("getConsumables > axios res=", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("getConsumables > axios err=", err);
                    reject("Error in getConsumables axios!");
                });
        } catch (error) {
            console.error("in SearchService > getConsumables, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getExtWork = (query) => {
    console.log("SearchService > getExtWork called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SEARCH_EXTWORK(query), getAccessToken())
                .then((res) => {
                    console.log("getExtWork > axios res=", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("getExtWork > axios err=", err);
                    reject("Error in getExtWork axios!");
                });
        } catch (error) {
            console.error("in SearchService > getExtWork, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

//Search Equipment based on the search criteria
export const equipmentSearch = async (searchStr) => {
    console.log("searchServices > equipmentSearch called...");
    return new Promise(async (resolve, reject) => {
        try {
            await axios
                .get(SEARCH_EQUIPMENT(searchStr), getAccessToken())
                .then((res) => {
                    console.log("equipmentSearch > axios res=", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        console.log("Status:", res.status);
                        reject("Error in Search Sparepart axios!");
                    }
                })
                .catch((err) => {
                    console.log("equipmentSearch > axios err=", err);
                    reject("Error in itemSearch axios!");
                });
        } catch (error) {
            console.error("in RepairBuilder > equipmentSearch, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

//Search Parts based on the search criteria
export const partsSearch = async (searchStr) => {
    console.log("searchServices > partsSearch called...");
    return new Promise(async (resolve, reject) => {
        try {
            await axios
                .get(SEARCH_SPARC_PART(searchStr), getAccessToken())
                .then((res) => {
                    console.log("partsSearch > axios res=", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        console.log("Status:", res.status);
                        reject("Error in Search Sparepart axios!");
                    }
                })
                .catch((err) => {
                    console.log("partsSearch > axios err=", err);
                    reject("Error in itemSearch axios!");
                });
        } catch (error) {
            console.error("in RepairBuilder > partsSearch, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

//Search Claim based on the search criteria
export const claimSearchById = async (id) => {
    console.log("searchServices > claimSearchById called...");
    return new Promise(async (resolve, reject) => {
        try {
            await axios
                .get(`${CLAIM_MASTER_URL}/${id}`, getAccessToken())
                .then((res) => {
                    console.log("claimSearchById > axios res=", res);
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        console.log("Status:", res.status);
                        reject("Error in Search Sparepart axios!");
                    }
                })
                .catch((err) => {
                    console.log("claimSearchById > axios err=", err);
                    reject("Error in itemSearch axios!");
                });
        } catch (error) {
            console.error("in RepairBuilder > equipmentSearch, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

//Search Claim based on the search criteria to fill the header
export const claimSearchByField = (searchStr) => {
    console.log("searchServices > claimSearchByField called...");
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(CLIAM_SEARCH_BY_FIELDS_URL(searchStr), getAccessToken())
                .then((res) => {
                    console.log("claimSearchByField > axios res=", res);
                    if (res.status === 200) resolve(res.data);
                    else reject("Error occurred while fetching customer data");
                })
                .catch((err) => {
                    console.log("claimSearchByField > axios err=", err);
                    reject("Error in claimSearchByField axios!");
                });
        } catch (error) {
            console.error("in searchServices > claimSearchByField, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

//Search Claim value by claim value id
export const claimValueSearchById = async (id) => {
    console.log("searchServices > claimValueSearchById called...");
    return new Promise(async (resolve, reject) => {
        try {
            await axios
                .get(`${CLAIM_VALUE_MASTER_URL}/${id}`, getAccessToken())
                .then((res) => {
                    console.log("claimValueSearchById > axios res=", res);
                    resolve(res);
                    // if (res.status === 200) {
                    //     resolve(res.data);
                    // } else {
                    //     console.log("Status:", res.status);
                    //     reject("Error in Search claim Value By Id axios!");
                    // }
                })
                .catch((err) => {
                    console.log("claimValueSearchById > axios err=", err);
                    resolve({ status: 400 });
                    // reject("Error in claim Value Search By Id axios!");
                });
        } catch (error) {
            console.error("in searchServices > claimValueSearchById, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

//Search settlement value by claim value id
export const settlementValueSearchById = async (id) => {
    console.log("searchServices > settlementValueSearchById called...");
    return new Promise(async (resolve, reject) => {
        try {
            await axios
                .get(`${SETTLEMENT_VALUE_MASTER_URL}/${id}`, getAccessToken())
                .then((res) => {
                    console.log("settlementValueSearchById > axios res=", res);
                    resolve(res);
                    // if (res.status === 200) {
                    //     resolve(res.data);
                    // } else {
                    //     console.log("Status:", res.status);
                    //     reject("Error in Search settlement Value By Id axios!");
                    // }
                })
                .catch((err) => {
                    console.log("settlementValueSearchById > axios err=", err);
                    resolve({ status: 400 });
                    // reject("Error in settlement Value Search By Id axios!");
                });
        } catch (error) {
            console.error("in searchServices > settlementValueSearchById, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


//Search labour price
export const getLabourPrice = async (query) => {
    console.log("searchServices > getLabourPrice called...");
    return new Promise(async (resolve, reject) => {
        try {
            await axios
                .get(PRICING_LABOUR_PRICE_SEARCH(query), getAccessToken())
                .then((res) => {
                    console.log("getLabourPrice > axios res=", res);
                    resolve(res);
                    // if (res.status === 200) {
                    //     resolve(res.data);
                    // } else {
                    //     console.log("Status:", res.status);
                    //     reject("Error in Search settlement Value By Id axios!");
                    // }
                })
                .catch((err) => {
                    console.log("getLabourPrice > axios err=", err);
                    reject("Error in settlement Value Search By Id axios!");
                });
        } catch (error) {
            console.error("in searchServices > getLabourPrice, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};