import { Routes, Route, useNavigate } from "react-router-dom";

// Navigations URL import constants
import {
  claimAdministration,
  claimHistory,
  equipment,
  equipmentMasterComp,
  help,
  login,
  manageWarrantyReturns,
  orderHistory,
  partsSearch,
  warrantyAdministration,
  warrantyAnalytics,
} from "./navigation/navigationConst";

import "./App.css";
import HomeScreen from "../src/pages/HomeScreen";
import Cart from "../src/pages/Cart";
import Login from "./pages/Login";
import Dashboard from "./pages/common/Dashboard";
import NotFoundPage from "./pages/NotFoundPage";
import EquipmentMaster from "./pages/EquipmentMaster";
import OrderHistoryMaster from "./pages/OrderHistoryMaster";
import { useContext, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import AuthInit from "./AuthInit";
import Auth from "./pages/Auth";
import SearchCatalogue from "./pages/SearchCatalogue";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import WarrantyAdministration from "./pages/warrantyManagement/warrantyMaster/WarrantyAdministration";
import ClaimAdministration from "./pages/warrantyManagement/claimMaster/ClaimAdministration";
import WarrantyReturnMaster from "./pages/warrantyManagement/returnMaster/WarrantyReturnMaster";
import EquipmentDashboard from "./pages/warrantyManagement/equipment/EquipmentDashboard";
import AccountHelp from "./pages/user/AccountHelp";
import ClaimHistoryMaster from "./pages/ClaimHistoryMaster";
import PartsSearchDashboard from "./pages/warrantyManagement/parts/PartsSearchDashboard";
import PartsCart from "./pages/warrantyManagement/parts/PartsCart";
import PartsSearchMaster from "./pages/warrantyManagement/parts/PartsSearchMaster";
import WarrantyAnalytics from "./pages/warrantyManagement/warrantyMaster/WarrantyAnalytics";
// import WarrantyReturnMaster from "./pages/WarrantyMaster/WarrantyReturn/WarrantyReturnMaster";

function App() {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!auth.customerId && !auth.customerName) {
  //     navigate("/login");
  //   }
  // }, [auth]);
  // console.log(auth.didTryAutoLogin);
  return (
    <div id="main-wrapper" className="show">
      {!auth.didTryAutoLogin ? (
        <AuthInit />
      ) : (
        <Routes>
          <Route path={login} element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Dashboard />}>
            <Route index element={<HomeScreen />} />
            <Route path="/cart" element={<PartsCart />} />
            {/* <Route path="/cart" element={<Cart />} /> */}
            <Route path="/search" element={<SearchCatalogue />} />
            <Route path={help} element={<AccountHelp />} />

            <Route path={equipment} element={<EquipmentMaster />} />
            <Route path={partsSearch} element={<PartsSearchMaster />} />
            {/* <Route path={partsSearch} element={<PartsSearchDashboard />} /> */}
            <Route path={orderHistory} element={<OrderHistoryMaster />} />
            <Route path={claimHistory} element={<ClaimHistoryMaster />} />
            <Route path={warrantyAnalytics} element={<WarrantyAnalytics />} />
            <Route
              path={equipmentMasterComp}
              // element={<EquipmentMasterComponent />}
              element={<EquipmentDashboard />}
            />
            <Route
              path={warrantyAdministration}
              element={<WarrantyAdministration />}
            />
            <Route
              path={claimAdministration}
              element={<ClaimAdministration />}
            />
            <Route
              path={manageWarrantyReturns}
              element={<WarrantyReturnMaster />}
            />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
