import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import $ from "jquery";
import SelectFilter from "react-select";
import { Link } from "react-router-dom";

import CustomizedSnackbar from "../shared/CustomSnackBar";
import { claimSearchById, equipmentSearch } from "../services/searchServices";
import { callGetApi } from "../services/ApiCaller";
import { CLAIM_MASTER_URL, CLAIM_ORDER_MASTER_URL, WARRANTY_MASTER_URL, Warranty_Country_List_GET } from "../services/CONSTANTS";
import { API_SUCCESS } from "../services/ResponseCode";
import { GRID_STYLE, INPUT_SEARCH_NO_RESULT_FOUND_ERROR_MESSAGE } from "./common/constants";
import ClaimEditModal from "./warrantyManagement/claimMaster/ClaimEditModal";
import {
    warrantyCategoryOptions,
    warrantyRequestObj,
    warrantyStatusOptions,
    warrantyUnitOptions,
} from "./warrantyManagement/warrantyManagementConstants";
import ClaimRequestModal from "./warrantyManagement/claimMaster/ClaimRequestModal";
import ClaimOrderRequestModal from "./warrantyManagement/claimOrderManagement/ClaimOrderRequestModal";
import NewClaimOrderRequestModal from "./warrantyManagement/claimOrderManagement/orderRequestNew/NewClaimOrderRequestModal";

const ClaimHistoryMaster = () => {
    const [querySearchSelector, setQuerySearchSelector] = useState([
        {
            id: 0,
            selectCategory: "",
            selectOperator: "",
            inputSearch: "",
            selectOptions: [],
            selectedOption: "",
        },
    ]);

    const [warrantyRecord, setWarrantyRecord] = useState({
        ...warrantyRequestObj,
    });

    const [loading, setLoading] = useState(false);
    const [claimRecord, setClaimRecord] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);

    const [openClaimEditModal, setOpenClaimEditModal] = useState(false);
    const [openClaimRequestModal, setOpenClaimRequestModal] = useState(false);

    const [claimRecordDetail, setClaimRecordDetail] = useState(null);
    const [claimRecordId, setClaimRecordId] = useState(null);
    const [claimOrderId, setClaimOrderId] = useState(null);
    const [evaluationId, setEvaluationId] = useState(null);
    const [assesstmentId, setAssesstmentId] = useState(null);
    const [warrantyRecordId, setWarrantyRecordId] = useState(null);

    const [openReturnRequsterModal, setOpenReturnRequsterModal] = useState(false);
    const [partsRecords, setPartsRecords] = useState([]);
    const [countryList, setCountryList] = useState([]);

    useEffect(() => {
        getClaimList();
    }, [page, pageSize]);

    useEffect(() => {
        getCountryKeyValueList();
    }, []);

    // country key value list
    const getCountryKeyValueList = () => {
        const rUrl = `${Warranty_Country_List_GET}?pageNumber=${0}&pageSize=${10}`;
        callGetApi(null, rUrl, (response) => {
            if (response.status === API_SUCCESS) {
                const responseData = response.data;
                const options = [];
                responseData.map((row) =>
                    // options.push({ label: row.countryName, value: row.countryId })
                    options.push({
                        label: row.countryName,
                        value: row.countryName,
                    })
                );
                setCountryList(options);
            }
        });
    };

    // get claim list
    const getClaimList = () => {
        setLoading(true);
        const rUrl = `${CLAIM_ORDER_MASTER_URL}/search-by-fields?field_name=claimOrderStatus&field_value=CLOSED`;
        // const rUrl = `${CLAIM_ORDER_MASTER_URL}?pageNumber=${page}&pageSize=${pageSize}&sortColumn=claimRequestDate&orderBY=DESC`;
        callGetApi(
            null,
            rUrl,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    // getClaimOrderStatus(responseData);
                    setTotalRecord(responseData.length);
                    setClaimRecord(responseData);
                    setLoading(false);
                } else {
                    setClaimRecord([]);
                    setLoading(false);
                }
            },
            (error) => {
                setClaimRecord([]);
                setLoading(false);
            }
        );
    };

    const getClaimOrderStatus = async (responseData) => {
        const recordArr = [];

        for (let row of responseData) {
            // const searchStr = `${CLAIM_MASTER_URL}/${row.claimId}`;
            const res = await claimSearchById(row.claimId);

            recordArr.push({
                ...row,
                claimStatus: res.claimStatus,
            });
        }
        setClaimRecord(recordArr);
    };

    // Snack Bar State
    const [severity, setSeverity] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };

    const handleSnack = (snackSeverity, snackMessage) => {
        setSnackMessage(snackMessage);
        setSeverity(snackSeverity);
        setOpenSnack(true);
    };

    //  handle AND || OR operator
    const handleOperator = (e, id) => {
        let tempArray = [...querySearchSelector];
        let obj = tempArray[id];
        obj.selectOperator = e;
        tempArray[id] = obj;
        setQuerySearchSelector([...tempArray]);
    };

    // select search category
    const handleSearchCategory = (e, id) => {
        let tempArray = [...querySearchSelector];
        let obj = tempArray[id];
        obj.selectCategory = e;
        obj.inputSearch = "";
        tempArray[id] = obj;
        setQuerySearchSelector([...tempArray]);
    };

    const handleInputSearch = (e, id) => {
        let tempArray = [...querySearchSelector];
        let obj = tempArray[id];
        let searchString = tempArray[id].selectCategory.value + "~" + e.target.value;
        if (tempArray[id].selectCategory.value && e.target.value) {
            equipmentSearch(searchString)
                .then((res) => {
                    obj.selectOptions = res;
                    tempArray[id] = obj;
                    setQuerySearchSelector([...tempArray]);
                    $(`.scrollbar-${id}`).css("display", "block");
                })
                .catch((err) => {
                    handleSnack("error", "Error occurred while searching spare parts!");
                });
        } else {
            handleSnack("info", "Please fill search criteria!");
            obj.selectOptions = [];
        }
        obj.inputSearch = e.target.value;
    };

    const handleSearchListClick = (e, currentItem, obj1, id) => {
        let tempArray = [...querySearchSelector];
        let obj = tempArray[id];
        obj.inputSearch = currentItem[obj.selectCategory.value];
        obj.selectedOption = currentItem;
        tempArray[id] = obj;
        setQuerySearchSelector([...tempArray]);
        $(`.scrollbar-${id}`).css("display", "none");
    };

    const handleDeleteQuerySearch = () => {
        setQuerySearchSelector([
            {
                id: 0,
                selectCategory: "",
                selectOperator: "",
                inputSearch: "",
                selectOptions: [],
                selectedOption: "",
            },
        ]);
    };

    //
    const handleQuerySearchClick = async () => {
        $(".scrollbar").css("display", "none");
        var searchStr = "";
        querySearchSelector.map(function (item, i) {
            if (i === 0 && item.selectCategory.value && item.inputSearch) {
                searchStr = `field_name=` + item.selectCategory.key + "&field_value=" + item.inputSearch;
                // encodeURI('"' + item.inputSearch + '"');
                // searchStr =
                //   item.selectCategory.key +
                //   ":" +
                //   encodeURI('"' + item.inputSearch + '"');
            } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
                searchStr =
                    searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
            }
            return searchStr;
        });

        try {
            if (searchStr) {
                const rUrl = `${CLAIM_MASTER_URL}/search-by-fields?${searchStr}`;
                console.log("rUr; :::: ", rUrl);
                callGetApi(
                    null,
                    rUrl,
                    (response) => {
                        if (response.status === API_SUCCESS) {
                            const responseData = response.data;
                            if (responseData.length === 0) {
                                handleSnack("info", INPUT_SEARCH_NO_RESULT_FOUND_ERROR_MESSAGE);
                                setLoading(false);
                            } else {
                                getClaimOrderStatus(responseData);
                                // setClaimRecord(responseData);
                                setLoading(false);
                            }
                        } else {
                            handleSnack("info", INPUT_SEARCH_NO_RESULT_FOUND_ERROR_MESSAGE);
                            setLoading(false);
                        }
                    },
                    (error) => {
                        setLoading(false);
                        handleSnack("error", "Error occurred while fetching Claim History!");
                    }
                );
                // const res = await equipmentSearch(searchStr);
                // setMasterData(res);
            } else {
                handleSnack("info", "Please fill the search criteria!");
            }
        } catch (err) {
            handleSnack("error", "Error occurred while fetching Claim history!");
        }
    };

    const claimColumns = [
        {
            field: "claimOrderNumber",
            headerName: "Claim Number",
            flex: 1,
        },
        {
            field: "claimOrderStatus",
            headerName: "Claim Status",
            flex: 1,
            renderCell: ({ row }) => (
                <div className="d-flex align-items-center claim-status-div px-2 mx-2">
                    <span className="green-dot"></span>
                    <span className="mx-2">{row.claimOrderStatus}</span>
                </div>
            ),
        },
        {
            field: "claimType",
            headerName: "Claim Type",
            flex: 1,
        },
        {
            field: "rmaNumber",
            headerName: "RMA Number",
            flex: 1,
        },
        {
            field: "claimRequestDate",
            headerName: "Claim Date",
            flex: 1,
        },
        {
            field: "replacement",
            headerName: "Replacement",
            flex: 1,
            renderCell: ({ row }) => <div style={{ fontWeight: "bold" }}>{row.replacement ? "Yes" : "No"}</div>,
        },
        // {
        //     field: "action",
        //     type: "actions",
        //     headerName: "Action",
        //     flex: 1,
        //     cellClassName: "actions",
        //     getActions: ({ row }) => {
        //         return [
        //             <GridActionsCellItem
        //                 icon={
        //                     <div className="cursor">
        //                         <Tooltip title="Edit">
        //                             <EditOutlinedIcon
        //                                 onClick={() =>
        //                                     handleEditClaimDetails(row)
        //                                 }
        //                             />
        //                         </Tooltip>
        //                     </div>
        //                 }
        //                 label="Edit"
        //                 className="textPrimary"
        //                 color="inherit"
        //             />,
        //         ];
        //     },
        // },
    ];

    // claim table page no and size change
    const handleClaimPaginationChange = (pageNo, rowsPerPage) => {
        setPage(pageNo);
        setPageSize(rowsPerPage);
    };

    // edit Claim Details
    const handleEditClaimDetails = (row) => {
        const claimId = row["claimId"];
        setClaimRecordId(claimId);

        const warrantyId = row["warrantyId"];
        setWarrantyRecordId(warrantyId);

        const _claimOrderId = row["claimOrderId"];
        setClaimOrderId(_claimOrderId);

        const _evaluationId = row["evaluationId"];
        setEvaluationId(_evaluationId);

        const _assessmentId = row["assessmentId"];
        setAssesstmentId(_assessmentId);

        callGetApi(null, `${CLAIM_MASTER_URL}/${claimId}`, (response) => {
            if (response.status === API_SUCCESS) {
                const responseData = response.data;
                setClaimRecordDetail(responseData);
            }
        });

        setOpenClaimRequestModal(true);
        // const claimId = params.row["claimId"];
        // const warrantyId = params.row["warrantyId"];
        // if (warrantyId) {
        //   const rUrl = `${WARRANTY_MASTER_URL}/${warrantyId}`;
        //   callGetApi(null, rUrl, (response) => {
        //     if (response.status === API_SUCCESS) {
        //       const { installerDetails, customerDetails, ...restData } =
        //         response.data;
        //       const responseData = response.data;

        //       // get category key value pairs
        //       const _category = warrantyCategoryOptions.find(
        //         (obj) => obj.value === responseData.category
        //       );

        //       // get unit key value pairs
        //       const _unit = warrantyUnitOptions.find(
        //         (obj) => obj.value === responseData.unit
        //       );

        //       // get status key value pairs
        //       const _warrantyStatus = warrantyStatusOptions.find(
        //         (obj) => obj.value === responseData.warrantyStatus
        //       );

        //       // set warranty details
        //       setWarrantyRecord({
        //         ...responseData,
        //         category: _category || "",
        //         // basis: _basis || "",
        //         unit: _unit || "",
        //         warrantyStatus: _warrantyStatus || "",
        //       });
        //       // } else {
        //       //   handleSnack("error", "Something went wrong");
        //     }
        //   });
        // }
        // setClaimRecordDetail(params.row);
        // setClaimRecordId(claimId);
        // // setClaimData(params.row);
        // setOpenClaimEditModal(true);
        // // handleOpenClaimEditModal();
    };

    //
    const handleOpenClaimRequestModal = () => {
        setOpenClaimEditModal(false);
        setOpenClaimRequestModal(true);
    };

    
    // opem return requester model box
    const handleShowReturnRequetrModal = (row = null) => {
        if (row) {
            setPartsRecords([row]);
        } else {
            setPartsRecords([]);
        }
        setOpenClaimRequestModal(!openClaimRequestModal);
        setOpenReturnRequsterModal(!openReturnRequsterModal);
    };


    return (
        <>
            <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
            <div className="content-body" style={{ minHeight: "884px" }}>
                <div className="container-fluid">
                    <h4 className="font-weight-600 mb-0 mt-4 mb-3">Claim History</h4>
                    {/* <div className="bg-primary px-3 mb-3 border-radius-10">
            <div className="row align-items-center">
              <div className="col-12 mx-2">
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex align-items-center bg-primary w-100">
                    <div
                      className="d-flex mr-3 py-3"
                      style={{ whiteSpace: "pre" }}
                    >
                      <h5 className="mr-2 mb-0 text-white">
                        <span>Search</span>
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mr-4">
                      <div className="row align-items-center m-0">
                        {querySearchSelector.map((obj, i) => (
                          <div
                            className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                            style={{ position: "relative", zIndex: 20 - i }}
                            key={"query" + i}
                          >
                            {i > 0 && (
                              <SelectFilter
                                isClearable={true}
                                defaultValue={{ label: "And", value: "AND" }}
                                options={[
                                  { label: "And", value: "AND", id: i },
                                  { label: "OR", value: "OR", id: i },
                                ]}
                                placeholder="And/Or"
                                onChange={(e) => handleOperator(e, i)}
                                value={obj.selectOperator}
                              />
                            )}

                            <div>
                              <SelectFilter
                                // isClearable={true}
                                options={[
                                  {
                                    label: "Serial No",
                                    value: "makerSerialNumber",
                                    key: "serialNumber",
                                  },
                                  {
                                    label: "Equipment Id",
                                    value: "equipmentNumber",
                                    key: "equipmentNumber",
                                  },
                                ]}
                                onChange={(e) => handleSearchCategory(e, i)}
                                value={obj.selectCategory}
                              />
                            </div>
                            <div className="customselectsearch customize">
                              <input
                                className="custom-input-sleact pr-1"
                                style={{ position: "relative" }}
                                type="text"
                                placeholder="Search string"
                                value={obj.inputSearch}
                                onChange={(e) => handleInputSearch(e, i)}
                                id={"inputSearch-" + i}
                                autoComplete="off"
                              />
                              {querySearchSelector.length - 1 === i && (
                                <div
                                  className="btn bg-primary text-white"
                                  onClick={handleQuerySearchClick}
                                >
                                  <span className="mr-2">
                                    <SearchIcon />
                                  </span>
                                  SEARCH
                                </div>
                              )}
                              {obj.selectOptions &&
                                obj.selectOptions.length !== 0 && (
                                  <ul
                                    className={`list-group customselectsearch-list scrollbar-repair-autocomplete scrollbar-${i} style`}
                                    id="style"
                                  >
                                    {obj.selectOptions.map((currentItem, j) => (
                                      <li
                                        className="list-group-item"
                                        key={j}
                                        onClick={(e) =>
                                          handleSearchListClick(
                                            e,
                                            currentItem,
                                            obj,
                                            i
                                          )
                                        }
                                      >
                                        {currentItem[obj.selectCategory.value]}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                            </div>
                          </div>
                        ))}
                        <div>
                          <Link
                            to="#"
                            className="btn-sm text-black border mr-2"
                            style={{ border: "1px solid #872FF7" }}
                          >
                            <span style={{ color: "white" }}>+</span>
                          </Link>
                        </div>

                        <div onClick={handleDeleteQuerySearch}>
                          <Link to="#" className="btn-sm border mr-2">
                            <i
                              className="fa fa-trash fa-lg"
                              style={{ color: "white" }}
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
                    <DataGrid
                        loading={loading}
                        rows={claimRecord}
                        columns={claimColumns}
                        page={page}
                        autoHeight
                        pageSize={pageSize}
                        sx={GRID_STYLE}
                        onPageChange={(newPage) => handleClaimPaginationChange(newPage, pageSize)}
                        onPageSizeChange={(newPageSize) => handleClaimPaginationChange(page, newPageSize)}
                        rowCount={totalRecord}
                        rowsPerPageOptions={[10, 20, 50]}
                        // paginationMode="server"
                        disableRowSelectionOnClick
                        getRowId={(row) => row.claimOrderId}
                    />
                </div>
            </div>

            {openClaimEditModal && (
                <ClaimEditModal
                    show={openClaimEditModal}
                    hideModal={() => setOpenClaimEditModal(false)}
                    warrantyRecord={warrantyRecord}
                    handleSnack={handleSnack}
                    claimRecordId={claimRecordId}
                    handleOpenClaimRequestModal={handleOpenClaimRequestModal}
                />
            )}

            {openClaimRequestModal && (
                <NewClaimOrderRequestModal
                    show={openClaimRequestModal}
                    hideModal={() => setOpenClaimRequestModal(false)}
                    handleSnack={handleSnack}
                    claimRecordDetail={claimRecordDetail}
                    warrantyRecordId={warrantyRecordId}
                    claimOrderId={claimOrderId}
                    setClaimRecordId={setClaimRecordId}
                    setClaimOrderId={setClaimOrderId}
                    claimRecordId={claimRecordId}
                    assesstmentId={assesstmentId}
                    setAssesstmentId={setAssesstmentId}
                    evaluationId={evaluationId}
                    setEvaluationId={setEvaluationId}
                    byAuthCode={false}
                    handleShowReturnRequetrModal={handleShowReturnRequetrModal}
                    openReturnRequsterModal={openReturnRequsterModal}
                    partsRecords={partsRecords}
                    countryOptions={countryList}
                />
            )}
            {/* {openClaimRequestModal && (
                <ClaimOrderRequestModal
                    show={openClaimRequestModal}
                    hideModal={() => setOpenClaimRequestModal(false)}
                    handleSnack={handleSnack}
                    claimRecordDetail={claimRecordDetail}
                    warrantyRecordId={warrantyRecordId}
                    claimOrderId={claimOrderId}
                    setClaimRecordId={setClaimRecordId}
                    setClaimOrderId={setClaimOrderId}
                    claimRecordId={claimRecordId}
                    assesstmentId={assesstmentId}
                    setAssesstmentId={setAssesstmentId}
                    evaluationId={evaluationId}
                    setEvaluationId={setEvaluationId}
                    byAuthCode={false}
                    handleShowReturnRequetrModal={handleShowReturnRequetrModal}
                    openReturnRequsterModal={openReturnRequsterModal}
                    partsRecords={partsRecords}
                    countryOptions={countryList}
                />
            )} */}
        </>
    );
};

export default ClaimHistoryMaster;
