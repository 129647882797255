export const splitUserName = (auth, showFullName = false, showCode = false) => {
  let userName = "";
  if (auth) {
    const customerName = auth?.customerName;
    if (customerName && customerName.length > 0 && customerName.includes("-")) {
      if (showFullName && showCode) {
        userName = `${auth?.customerName ? `${auth?.customerName.split("-")[0]} - ` : ""}${
          auth?.customerName ? (customerName.includes("-") ? auth?.customerName.split("-")[1] : auth?.customerName) : ""
        }`;
      } else if (showFullName && !showCode) {
        userName = `${auth?.customerName ? (customerName.includes("-") ? auth?.customerName.split("-")[1] : auth?.customerName) : ""}`;
      } else if (!showFullName && showCode) {
        userName = `${auth?.customerName ? `${auth?.customerName.split("-")[0]} - ` : ""}`;
      }
    } else {
      if (showFullName && showCode) {
        userName = `${auth?.customerId ? `${auth?.customerId} - ` : ""}${
          auth?.customerName ? (customerName.includes("-") ? auth?.customerName.split("-")[1] : auth?.customerName) : ""
        }`;
      } else if (showFullName && !showCode) {
        userName = `${auth?.customerName ? (customerName.includes("-") ? auth?.customerName.split("-")[1] : auth?.customerName) : ""}`;
      } else if (!showFullName && showCode) {
        userName = `${auth?.customerId ? `${auth?.customerId} - ` : ""}`;
      }
    }
    return userName;
  }
  return userName;
};
