import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Box, Grid, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import $ from "jquery";
import SelectFilter from "react-select";
import { Link } from "react-router-dom";

import CustomizedSnackbar from "../../../shared/CustomSnackBar";
import { equipmentSearch } from "../../../services/searchServices";
import { GRID_STYLE } from "../../common/constants";
import EquipmentSummaryModal from "./EquipmentSummaryModal";
import EquipmentDetailsModal from "./EquipmentDetailsModal";
import EquipmentCreateModal from "./EquipmentCreateModal";
import ClaimDetailsModal from "../claimMaster/ClaimDetailsModal";
import WarrantyOverviewModal from "../warrantyMaster/WarrantyOverviewModal";
import EquipmentEnrollModal from "./EquipmentEnrollModal";
import ClaimReportModal from "./ClaimReportModal";
import { Warranty_Country_List_GET } from "../../../services/CONSTANTS";
import { API_SUCCESS } from "../../../services/ResponseCode";
import { callGetApi } from "../../../services/ApiCaller";
import WarrantyContractSummary from "./WarrantyContractSummary";

// Equipment Master Search Select Options
const equipmentSearchOptions = [
  { label: "Serial No", value: "makerSerialNumber" },
  { label: "Model", value: "model" },
  { label: "Family", value: "family" },
  { label: "Equipment Id", value: "equipmentNumber" },
  { label: "Description", value: "description" },
];

const EquipmentDashboard = () => {
  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);
  const [masterData, setMasterData] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalEquipmentCount, setTotalEquipmentCount] = useState(0);

  const [isSummaryModal, setIsSummaryModal] = useState(false);
  const [isEquWarrantyModal, setIsEquWarrantyModal] = useState(false);
  const [isOverviewModal, setIsOverviewModal] = useState(false);

  const [openEquSummaryModal, setOpenEquSummaryModal] = useState(false);
  const [openEquDtlModal, setOpenEquDtlModal] = useState(false);
  const [openEquCreateModal, setOpenEquCreateModal] = useState(false);
  const [serialNumber, setSerialNumber] = useState(null);
  const [equRecordId, setEquRecordId] = useState(null);
  const [equipmentData, setEquipmentData] = useState(null);
  const [isReportModal, setIsReportModal] = useState(false);

  const [equpWarrantyId, setEqupWarrantyId] = useState(null);
  const [equipmentDesc, setEquipmentDesc] = useState("");

  const [claimRecordDetail, setClaimRecordDetail] = useState(null);
  const [claimRecordId, setClaimRecordId] = useState(null);
  const [openClaimDetailsModal, setOpenClaimDetailsModal] = useState(false);

  const [warrantyRecordId, setWarrantyRecordId] = useState(null);
  const [showOverviewModal, setShowOverviewModal] = useState(false);
  const [openClaimCreateModal, setOpenClaimCreateModal] = useState(false);
  const [openClaimEditModal, setOpenClaimEditModal] = useState(false);
  const [openClaimRequestModal, setOpenClaimRequestModal] = useState(false);
  const [openPartCreateModal, setOpenPartCreateModal] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [openClaimQuestionModal, setOpenClaimQuestionModal] = useState(false);

  const [showJobHoursModal, setShowJobHoursModal] = useState(false);
  const [showClaimErrorModal, setShowClaimErrorModal] = useState(false);
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [showClaimFileUploadModal, setShowClaimFileUploadModal] = useState(false);
  const [showViewFileModal, setShowViewFileModal] = useState(false);

  const [openEqupEnrollModal, setOpenEqupEnrollModal] = useState(false);
  const [editEnrolement, setEditEnrolement] = useState(false);
  const [isEnrolement, setIsEnrolement] = useState(false);

  const [equWarrantyCreate, setEquWarrantyCreate] = useState(false);

  const [openClaimReport, setOpenClaimReport] = useState(false);

  const [openReturnRequsterModal, setOpenReturnRequsterModal] = useState(false);
  const [partsRecords, setPartsRecords] = useState([]);
  const [countryList, setCountryList] = useState([]);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  //  handle AND || OR operator
  const handleOperator = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectOperator = e;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  useEffect(() => {
    getCountryKeyValueList();
  }, []);

  // country key value list
  const getCountryKeyValueList = () => {
    const rUrl = `${Warranty_Country_List_GET}?pageNumber=${0}&pageSize=${10}`;
    callGetApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        const options = [];
        responseData.map((row) =>
          // options.push({ label: row.countryName, value: row.countryId })
          options.push({
            label: row.countryName,
            value: row.countryName,
          })
        );
        setCountryList(options);
      }
    });
  };

  // select search category
  const handleSearchCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // equipment input search
  const handleInputSearch = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    let searchString = tempArray[id].selectCategory.value + "~" + e.target.value;
    if (tempArray[id].selectCategory.value && e.target.value) {
      equipmentSearch(searchString)
        .then((res) => {
          obj.selectOptions = res;
          tempArray[id] = obj;
          setQuerySearchSelector([...tempArray]);
          $(`.scrollbar-${id}`).css("display", "block");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching spare parts!");
        });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
    }
    obj.inputSearch = e.target.value;
  };

  // equipment input search list click
  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.inputSearch = currentItem[obj.selectCategory.value];
    obj.selectedOption = currentItem;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // delete equipment search query list
  const handleDeleteQuerySearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
  };

  // click on search button
  const handleQuerySearchClick = async () => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        const res = await equipmentSearch(`${searchStr}&pageNumber=0&pageSize=10`);
        setMasterData(res);
        if (res.length !== 0) {
          setTotalEquipmentCount(res[0].totalRecords);
        }
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  const fetchEquipmentList = async (pageNo, rowsPerPage) => {
    setPage(pageNo);
    setPageSize(rowsPerPage);

    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        const res = await equipmentSearch(`${searchStr}&pageNumber=${pageNo}&pageSize=${rowsPerPage}`);
        setMasterData(res);
        if (res.length !== 0) {
          setTotalEquipmentCount(res[0].totalRecords);
        }
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  // columns
  const columns = [
    {
      field: "id",
      headerName: "ID #",
      flex: 1,
    },
    {
      field: "makerSerialNumber",
      headerName: "Serial Number",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
    },
    {
      field: "yearOfManufacture",
      headerName: "Manufacture Date",
      flex: 1,
    },
    {
      field: "installationDate",
      headerName: "Date of Install",
      flex: 1,
    },
    // {
    //   field: "customerId",
    //   headerName: "Customer Id",
    //   flex: 1,
    // },
    {
      field: "currentClient",
      headerName: "Customer Number",
      flex: 1,
    },
    {
      field: "customer",
      headerName: "Customer Name",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              //   <div className="cursor" onClick={() => handleOpenEquSummaryModal(row)}>
              <div className="cursor" onClick={() => handleOpenWarrantyContarctSummaryModal(row)}>
                <Tooltip title="Summary">
                  <VisibilityIcon />
                </Tooltip>
              </div>
            }
            label="Summary"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div
                className=" cursor"
                // onClick={() => handleOpenWarrantyOverview(row)}
                onClick={() => handleOpenEquEnrollModal(row)}
              >
                <Tooltip title="Edit Details">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div
                className=" cursor"
                // onClick={() => handleOpenClaimHistory(row)}
                onClick={() => handleOpenEquOverviewModal(row)}
              >
                <Tooltip title="Overview">
                  <img
                    className="text-primary"
                    src="../../assets/images/claimReport.png"
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </Tooltip>
              </div>
            }
            label="Overview"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // open equipment summary modal
  const handleOpenEquSummaryModal = (row) => {
    const equipmentId = row["id"];
    setEquRecordId(equipmentId);

    const serialNo = row["makerSerialNumber"];
    setSerialNumber(serialNo);

    setEquipmentData(row);
    setOpenEquSummaryModal(true);
    setIsSummaryModal(true);
    setIsOverviewModal(false);
  };

  // open Equipment Warranty/Contract Summary Modal
  const handleOpenWarrantyContarctSummaryModal = (row) => {
    if (!row?.warrantyId) {
      handleSnack("info", "There is no Equipment warranty, for this Equipment");
      return;
    }
    setEqupWarrantyId(row["warrantyId"]);
    setEquipmentDesc(row["description"]);
    setOpenEquSummaryModal(true);
  };

  // open equipment edit details modal(as Equipment Enroll modal)
  const handleOpenEquEnrollModal = (row) => {
    const equpmentId = row["id"];
    setEquRecordId(equpmentId);

    const serialNo = row["makerSerialNumber"];
    setSerialNumber(serialNo);

    setEquipmentData(row);
    setIsSummaryModal(false);
    setIsOverviewModal(false);
    setOpenEqupEnrollModal(true);
  };

  // open Equipment overview modal(as warranty Overview)
  const handleOpenEquOverviewModal = (row) => {
    const warrantyId = row["warrantyId"];
    setWarrantyRecordId(warrantyId);
    setEquipmentData(row);
    setIsEquWarrantyModal(false);
    setIsSummaryModal(false);
    setIsOverviewModal(true);
    setShowOverviewModal(true);
  };

  // view generated warranty Overview details
  const handleViewGenerateWarrantyOverview = (warrantyId) => {
    // const warrantyId = row["warrantyId"];
    setWarrantyRecordId(warrantyId);

    setOpenEqupEnrollModal(false);
    setIsEquWarrantyModal(false);
    setIsSummaryModal(false);
    setIsOverviewModal(true);
    setShowOverviewModal(true);
  };

  // show Equipment enroll modal from summary modal
  const handleShowEquEnrollModal = () => {
    if (isSummaryModal) {
      setOpenEquSummaryModal(!openEquSummaryModal);
      setOpenEqupEnrollModal(!openEqupEnrollModal);
    } else {
      setOpenEqupEnrollModal(!openEqupEnrollModal);
    }
  };

  // close equipment enroll modal and show claim order(Request create modal)
  const handleOpenCloseEquEnrollModal = () => {
    setOpenEqupEnrollModal(!openEqupEnrollModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // close equipment summary modal and show warranty request create modal
  const handleOpenWarrantyReqCreateModal = () => {
    setOpenEquSummaryModal(!openEquSummaryModal);
    setOpenEquCreateModal(!openEquCreateModal);
    setIsEquWarrantyModal(!isEquWarrantyModal);
  };

  // open claim request create modal from Equipment warranty request create modal
  const handleOpenModalClaimReqFromEquWarranty = () => {
    setOpenEquCreateModal(!openEquCreateModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // close claim request modal
  const handleCloseClaimReqModalFromEquWarranty = () => {
    setOpenClaimRequestModal(!openClaimRequestModal);
    setOpenEquSummaryModal(!openEquSummaryModal);
    setIsEquWarrantyModal(!isEquWarrantyModal);
  };

  // open new claim create modal from warranty overview modal
  const handleOpenClaimCreateModalFromOverview = () => {
    setShowOverviewModal(!showOverviewModal);
    setOpenClaimCreateModal(!openClaimCreateModal);
  };

  // open claim Edit modal from warranty overview
  const handleOpenClaimEditModalFromOverview = () => {
    setShowOverviewModal(!showOverviewModal);
    setOpenClaimEditModal(!openClaimEditModal);
  };

  // open claim request Process modal from warranty overview
  const handleOpenClaimRequestModalFromOverview = () => {
    setOpenClaimEditModal(false);
    setOpenClaimRequestModal(true);
  };

  //
  const handleRaiseClaimRequest = () => {
    setShowOverviewModal(!showOverviewModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // open/Close claim Request Questions Modal
  const handleOpenCloseClaimQuestions = () => {
    setShowOverviewModal(!showOverviewModal);
    setOpenClaimQuestionModal(!openClaimQuestionModal);
  };

  // open claim request modal from question modal
  const handleOpenClaimRequestToQuestionModal = () => {
    setOpenClaimQuestionModal(false);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // close claim request Process modal from warranty overview
  const handleCloseClaimRequestModalFromOverview = () => {
    setShowOverviewModal(!showOverviewModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // open file Upload Modal from warranty overview
  const handleShowFileUploadModalFromOverview = () => {
    setShowOverviewModal(!showOverviewModal);
    setOpenFileUploadModal(!openFileUploadModal);
  };

  // create equipment enrollement
  const handleCreateEquEnrollment = () => {
    setEquipmentData(null);
    setEquRecordId(null);
    setIsSummaryModal(false);
    setIsOverviewModal(false);

    setIsEquWarrantyModal(false);
    setOpenEqupEnrollModal(true);
  };

  // open file Upload Modal
  const handleShowEnrollFileUploadModal = () => {
    setOpenEqupEnrollModal(!openEqupEnrollModal);
    setOpenFileUploadModal(!openFileUploadModal);
  };

  // show job hours modal box
  const handleShowOrderJobHoursModal = () => {
    setShowJobHoursModal(!showJobHoursModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim errors modal box
  const handleShowClaimErrorsModal = () => {
    setShowClaimErrorModal(!showClaimErrorModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim Files modal box
  const handleShowFilesModal = () => {
    setShowFilesModal(!showFilesModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim Files upload modal box
  const handleShowClaimFileUploadModal = () => {
    setShowFilesModal(!showFilesModal);
    setShowClaimFileUploadModal(!showClaimFileUploadModal);
  };

  // show view files(image modal)
  const handleShowViewFileModal = () => {
    setShowViewFileModal(!showViewFileModal);
    setShowFilesModal(!showFilesModal);
  };

  // opem return requester model box
  const handleShowReturnRequetrModal = (row = null) => {
    if (row) {
      setPartsRecords([row]);
    } else {
      setPartsRecords([row]);
    }
    setOpenClaimRequestModal(!openClaimRequestModal);
    setOpenReturnRequsterModal(!openReturnRequsterModal);
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body bg-white" style={{ minHeight: "884px" }}>
        <div className="container-fluid mt-3">
          <h4 className="font-weight-600 mb-3 mt-4">Equipment Search</h4>
          <div className="bg-primary px-3 mb-3 border-radius-10">
            <div className="row align-items-center">
              <div className="col-12 mx-2">
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex align-items-center bg-primary w-100">
                    <div className="d-flex mr-3 py-3" style={{ whiteSpace: "pre" }}>
                      <h5 className="mr-2 mb-0 text-white">
                        <span>Search</span>
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mr-4">
                      <div className="row align-items-center m-0">
                        {querySearchSelector.map((obj, i) => (
                          <div
                            className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                            style={{
                              position: "relative",
                              zIndex: 20 - i,
                            }}
                            key={"query" + i}
                          >
                            {i > 0 && (
                              <SelectFilter
                                isClearable={true}
                                defaultValue={{
                                  label: "And",
                                  value: "AND",
                                }}
                                options={[
                                  {
                                    label: "And",
                                    value: "AND",
                                    id: i,
                                  },
                                  {
                                    label: "OR",
                                    value: "OR",
                                    id: i,
                                  },
                                ]}
                                placeholder="And/Or"
                                onChange={(e) => handleOperator(e, i)}
                                value={obj.selectOperator}
                              />
                            )}

                            <div>
                              <SelectFilter
                                // isClearable={true}
                                options={equipmentSearchOptions}
                                onChange={(e) => handleSearchCategory(e, i)}
                                value={obj.selectCategory}
                              />
                            </div>
                            <div className="customselectsearch customize">
                              <input
                                className="custom-input-sleact pr-1"
                                style={{
                                  position: "relative",
                                }}
                                type="text"
                                placeholder="Search string"
                                value={obj.inputSearch}
                                onChange={(e) => handleInputSearch(e, i)}
                                id={"inputSearch-" + i}
                                autoComplete="off"
                              />
                              {querySearchSelector.length - 1 === i && (
                                <div className="btn bg-primary text-white" onClick={() => handleQuerySearchClick()}>
                                  <span className="mr-2">
                                    <SearchIcon />
                                  </span>
                                  SEARCH
                                </div>
                              )}
                              {obj.selectOptions && obj.selectOptions.length > 0 && (
                                <ul className={`list-group customselectsearch-list scrollbar-repair-autocomplete scrollbar-${i} style`} id="style">
                                  {obj.selectOptions.map((currentItem, j) => (
                                    <li className="list-group-item" key={j} onClick={(e) => handleSearchListClick(e, currentItem, obj, i)}>
                                      {currentItem[obj.selectCategory.value]}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        ))}
                        <div>
                          <Link
                            to="#"
                            className="btn-sm text-black border mr-2"
                            style={{
                              border: "1px solid #872FF7",
                            }}
                          >
                            <span
                              style={{
                                color: "white",
                              }}
                            >
                              +
                            </span>
                          </Link>
                        </div>

                        <div onClick={handleDeleteQuerySearch}>
                          <Link to="#" className="btn-sm border mr-2">
                            <i
                              className="fa fa-trash fa-lg"
                              style={{
                                color: "white",
                              }}
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn bg-white text-dark font-weight-500 mx-2" onClick={handleCreateEquEnrollment}>
                    Enroll Equipment
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <Box
              sx={{
                width: "100%",
                // height: 400,
                // marginBottom: 8,
                // marginInline: 2,
              }}
            >
              <DataGrid
                sx={GRID_STYLE}
                rows={masterData}
                columns={columns}
                autoHeight
                page={page}
                pageSize={pageSize}
                onPageChange={(newPage) => fetchEquipmentList(newPage, pageSize)}
                onPageSizeChange={(newPageSize) => fetchEquipmentList(page, newPageSize)}
                rowCount={totalEquipmentCount}
                rowsPerPageOptions={[5, 10, 20, 50]}
                getRowId={(row) => row.id}
              />
            </Box>
          </div>
        </div>
      </div>
      {/* {openEquSummaryModal && (
        <EquipmentSummaryModal
          show={openEquSummaryModal}
          handleClose={() => setOpenEquSummaryModal(false)}
          handleSnack={handleSnack}
          serialNumber={serialNumber}
          handleViewDetails={handleShowEquEnrollModal}
          handleConfirm={handleOpenWarrantyReqCreateModal}
          equipmentRecord={equipmentData}
        />
      )} */}
      {openEquSummaryModal && (
        <WarrantyContractSummary
          show={openEquSummaryModal}
          handleClose={() => setOpenEquSummaryModal(false)}
          recordId={equpWarrantyId}
          equipmentDesc={equipmentDesc}
          handleSnack={handleSnack}
          serialNumber={serialNumber}
          handleViewDetails={handleShowEquEnrollModal}
          handleConfirm={handleOpenWarrantyReqCreateModal}
          equipmentRecord={equipmentData}
        />
      )}

      {!isOverviewModal && !isEquWarrantyModal && (openEqupEnrollModal || openClaimRequestModal || openFileUploadModal) && (
        <EquipmentEnrollModal
          show={openEqupEnrollModal}
          hideModal={handleShowEquEnrollModal}
          handleSnack={handleSnack}
          equRecordId={equRecordId}
          setEquRecordId={setEquRecordId}
          equipmentRecord={equipmentData}
          openClaimRequestModal={openClaimRequestModal}
          handleCloseRequestModal={handleOpenCloseEquEnrollModal}
          openFileUploadModal={openFileUploadModal}
          handleShowFileUploadModal={handleShowEnrollFileUploadModal}
          handleViewGenerateWarrantyOverview={handleViewGenerateWarrantyOverview}
        />
      )}

      {!isOverviewModal && isEquWarrantyModal && (openEquCreateModal || openClaimRequestModal) && (
        <EquipmentCreateModal
          show={openEquCreateModal}
          hideModal={handleOpenWarrantyReqCreateModal}
          handleSnack={handleSnack}
          serialNumber={serialNumber}
          equipmentRecord={equipmentData}
          setClaimRecordId={setClaimRecordId}
          setClaimRecordDetail={setClaimRecordDetail}
          // handleShowClaimDetailsModal={handleShowClaimDetailsModal}
          handleOpenClaimRequestModal={handleOpenModalClaimReqFromEquWarranty}
          setEquWarrantyCreate={setEquWarrantyCreate}
          openClaimRequestModal={openClaimRequestModal}
          handleCloseRequestModal={handleCloseClaimReqModalFromEquWarranty}
        />
      )}

      {isOverviewModal &&
        !isEquWarrantyModal &&
        !isSummaryModal &&
        (showOverviewModal ||
          openClaimCreateModal ||
          openClaimEditModal ||
          openClaimRequestModal ||
          openPartCreateModal ||
          openFileUploadModal ||
          openClaimQuestionModal ||
          showJobHoursModal ||
          showClaimErrorModal ||
          showFilesModal ||
          showClaimFileUploadModal ||
          (showViewFileModal && openReturnRequsterModal)) && (
          <WarrantyOverviewModal
            show={showOverviewModal}
            warrantyRecordId={warrantyRecordId}
            hideModal={() => setShowOverviewModal(false)}
            handleSnack={handleSnack}
            openClaimCreateModal={openClaimCreateModal}
            handleClaimCreate={handleOpenClaimCreateModalFromOverview}
            openClaimEditModal={openClaimEditModal}
            handleOpenClaimEditModal={handleOpenClaimEditModalFromOverview}
            handleOpenClaimRequestModal={handleOpenClaimRequestModalFromOverview}
            handleCloseClaimRequestModal={handleCloseClaimRequestModalFromOverview}
            openClaimRequestModal={openClaimRequestModal}
            openPartCreateModal={openPartCreateModal}
            openFileUploadModal={openFileUploadModal}
            handleShowFileUploadModal={handleShowFileUploadModalFromOverview}
            isReportModal={isReportModal}
            handleRaiseClaimRequest={handleRaiseClaimRequest}
            openClaimQuestionModal={openClaimQuestionModal}
            handleOpenCloseClaimQuestions={handleOpenCloseClaimQuestions}
            handleOpenClaimRequestToQuestionModal={handleOpenClaimRequestToQuestionModal}
            equipmentRowData={equipmentData}
            showJobHoursModal={showJobHoursModal}
            handleShowOrderJobHoursModal={handleShowOrderJobHoursModal}
            showClaimErrorModal={showClaimErrorModal}
            handleShowClaimErrorsModal={handleShowClaimErrorsModal}
            showFilesModal={showFilesModal}
            handleShowFilesModal={handleShowFilesModal}
            showClaimFileUploadModal={showClaimFileUploadModal}
            handleShowClaimFileUploadModal={handleShowClaimFileUploadModal}
            showViewFileModal={showViewFileModal}
            handleShowViewFileModal={handleShowViewFileModal}
            handleShowReturnRequetrModal={handleShowReturnRequetrModal}
            openReturnRequsterModal={openReturnRequsterModal}
            partsRecords={partsRecords}
            countryOptions={countryList}
          />
        )}
    </>
  );
};

export default EquipmentDashboard;
